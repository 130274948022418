import { ref, watch, computed, defineComponent, Ref } from "vue";
import { useReservation } from "./";
import { useMediaQuery } from "@vueuse/core";
import lookupService from "@/services/LookupService";
import webinsService from "@/services/WebinsService";
import { Area, CartCallbackItem, DatiIniziali, PickPoint, WidgetOptions } from "@/models";
import { TextValueItem } from "@/models/TextValueItem";
import { windowSize } from "@/models/WindowSize";
import { Provincia, Comune } from "@/models/Province";
import { FasciaEta } from "@/models/FasciaEta";
import dayjs from "dayjs";
import { WidgetConfigItem } from "@/models/LoginResponse";

const areas = ref<Area[]>([]);
const forcedArea = ref<Area>(null);
const forcedPickPoint = ref<PickPoint>(null);
const appCode = ref("");
const defaultPaymnetAppId = ref("");
const widgetConfig = ref<WidgetConfigItem>(null);
const mapServiceApiKey = ref("");
const openTime = ref(9);
const closeTime = ref(18);
const options = ref<WidgetOptions>(null);

const token = ref("");
const destinazioniPreventivatore = ref<TextValueItem[]>([]);
const elencoEta = ref<TextValueItem[]>([]);
const elencoFasceEta = ref<FasciaEta[]>([]);
const province = ref<Provincia[]>([]);
const comuni = ref<Comune[]>([]);
const datiIniziali = ref<DatiIniziali>();

const WindowSize = ref<windowSize>({ height: 0, width: 0 });
const appWidth = ref<number>(1920);

const brkp = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export function useWidget() {
    //const {reservation } = useReservation();
    const loading = ref(false);
    const isAppIdValid = computed(() => {
        return appCode.value && appCode.value.length > 0;
    });
    const isPaymentInternal = computed(() => {
        //return !(options.value && options.value.addToCartCallback);
        return widgetConfig.value && widgetConfig.value.abilitaAggiuntaCarrello ? false : true;
    });

    const modeCar = computed(() => options.value.scope > 1);

    const isAppMobile = computed(() => {
        let pixelRatio = 1;

        if (window && window.devicePixelRatio > 1) {
            pixelRatio = window.devicePixelRatio;
        }

        return appWidth.value / pixelRatio < brkp.xl;
    });

    const decodificaStato = (statoDesc: string): number => {
        if (destinazioniPreventivatore.value && destinazioniPreventivatore.value.length && statoDesc) {
            if(widgetConfig.value && widgetConfig.value.usaAlphaCode3Destinazione){
                const d = destinazioniPreventivatore.value.find((x) => x.code.toLowerCase() === statoDesc.toLowerCase().trim());
                return d && d.value ? parseInt(d.value as any) : null;
            } 
            else {
                const d = destinazioniPreventivatore.value.find((x) => x.text.toLowerCase() === statoDesc.toLowerCase().trim());
                return d && d.value ? parseInt(d.value as any) : null;
            }
        }

        
        return null;
    };

    const setupAppWebins = async (wOptions: WidgetOptions) => {
        //(code: string, idScope?: number) => {
        if (!wOptions.scope || wOptions.scope <= 0) {
            wOptions.scope = 1;
        }

        Date.prototype.toJSON = function () {
            const m = dayjs(this);
            return m.format("YYYY-MM-DD");
        };

        options.value = wOptions;

        datiIniziali.value = wOptions.datiIniziali as DatiIniziali;

        const onWindowResize = () => {
            WindowSize.value = {
                width: window.innerWidth,
                height: window.innerHeight
            };
            appWidth.value = window.innerWidth;
        };

        window.addEventListener("resize", onWindowResize);

        onWindowResize();

        if (wOptions.code != null) {
            const widgetInfo = await fetchData(loading, webinsService.login(wOptions.code));
            if (widgetInfo) {
                token.value = widgetInfo.token;
                defaultPaymnetAppId.value = widgetInfo.payPalAppID;
                appCode.value = wOptions.code;
                widgetConfig.value = widgetInfo.widgetConfig;

                const resDest = await fetchData(loading, webinsService.getDestinazioniPreventivatore());

                const resEta = await fetchData(loading, webinsService.getElencoEta());

                const resFasceEta = await fetchData(loading, webinsService.getElencoFasceEta());

                const resProvince = await fetchData(loading, webinsService.getElencoProvince());

                const resComuni = await fetchData(loading, webinsService.getElencoComuni());

                destinazioniPreventivatore.value = resDest;

                // elencoFasceEta.value.push( {codiceFascia: "01", text:"Da 0 a 2", da: 0, a: 2});
                // elencoFasceEta.value.push( {codiceFascia: "02", text:"Da 3 a 17", da: 3, a: 17});
                // elencoFasceEta.value.push( {codiceFascia: "03", text:"Da 18 a 39", da: 18, a: 39});
                // elencoFasceEta.value.push( {codiceFascia: "04", text:"Da 40 a 79", da: 40, a: 79});
                // elencoFasceEta.value.push( {codiceFascia: "05", text:"Oltre 80", da: 80, a: null});

                elencoEta.value = resEta;
                elencoFasceEta.value = resFasceEta;
                province.value = resProvince;
                comuni.value = resComuni;
                //const area = appObj.object as Area;
                // if (widgetInfo.mode.area != null) {
                //   areas.value = [widgetInfo.mode.area];
                // }
                // forcedArea.value = widgetInfo.mode.area;
                // forcedPickPoint.value = widgetInfo.mode.pickPoint;
                // defaultPaymnetAppId.value = widgetInfo.paymentServiceDefaultAppId;
                // mapServiceApiKey.value = widgetInfo.mapServiceApiKey;

                // openTime.value = Math.floor(widgetInfo.openTime / 60);
                // closeTime.value = Math.floor(widgetInfo.closeTime / 60);
            } else {
                throw new Error("Widget code initialization failed");
            }
        } else {
            appCode.value = null;
            await fetchAreas();
        }
    };

    const _displayError = (el: Element, error: string) => {
        if (el != null) {
            el.innerHTML = `<div style='margin: 0 auto; color: red; padding: 20px; margin: 20px;background-color: white'>${error}</div>`;
        }
    };

    const _displayInfo = (el: Element, info: string) => {
        if (el != null) {
            el.innerHTML = `<div style='margin: 0 auto; color: lightblue; padding: 20px; margin: 20px;background-color: white'>${info}</div>`;
        }
    };

    async function fetchAreas(): Promise<void> {
        const returnedAreas = await fetchData(loading, lookupService.getAreas());
        areas.value = returnedAreas;
    }

    const fetchData = async <T>(loading: Ref<boolean>, func: Promise<T>) => {
        loading.value = true;

        try {
            //areas.value = await lookupService.getAreas();
            return await func;
        } catch (err) {
            console.log("Error fetching data from server: " + err);
            return null;
        } finally {
            loading.value = false;
        }
    };

    const callCartCallback = (callbackData: CartCallbackItem): Promise<string> => {
        loading.value = true;

        return new Promise((resolve, reject) => {
            const res = options.value.addToCartCallback(callbackData); // vedere cosa bisogna passare, probabilmente dati polizza

            res.then((msg) => {
                debugger;
                resolve(msg);
            }).catch((msgErr) => {
                debugger;
                reject(msgErr);
            }).finally(() => {
                debugger;
                loading.value = false;
            });
        });
    };

    const isLargeScreen = useMediaQuery("(min-width: 1000px)");

    return {
        loading,
        isLargeScreen,
        appCode,
        options,
        modeCar,
        openTime,
        closeTime,
        isPaymentInternal,
        callCartCallback,
        defaultPaymnetAppId,
        widgetConfig,
        mapServiceApiKey,
        forcedArea,
        forcedPickPoint,
        isAppIdValid,
        token,
        destinazioniPreventivatore,
        decodificaStato,
        elencoEta,
        elencoFasceEta,
        province,
        comuni,
        datiIniziali,
        setupAppWebins,
        isAppMobile,
        fetchData,
        areas
    };
}
