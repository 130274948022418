import { BaseService } from "./BaseService";
import { AvailabilityRequest } from "./requests/AvailabilityRequest";
import { CalculateAmountsRequest } from "./requests/CalculateAmountsRequest";
import { CreateReservationRequest } from "./requests/CreateReservationRequest";
import { ApiResponse } from "./responses/ApiResponse";
import { AvailabilityResponse } from "./responses/AvailabilityResponse";
import { CalculateAmountsResponse } from "./responses/CalculateAmountResponse";
import { CreateReservationResponse } from "./responses/CreateReservationResponse";
import { GetReservationResponse } from "./responses/GetReservationResponse";

class ReservationService extends BaseService {
  async searchAvailabilities(
    request: AvailabilityRequest
  ): Promise<AvailabilityResponse[]> {
    const response = await this.tryExecute(
      this.api.post<ApiResponse<AvailabilityResponse[]>>(
        "reservations/availabilities",
        request
      )
    );

    return this.handleApiResponse(response.data);
  }

  async createReservation(
    request: CreateReservationRequest
  ): Promise<CreateReservationResponse> {
    const response = await this.tryExecute(
      this.api.post<ApiResponse<CreateReservationResponse>>(
        "reservations/create",
        request
      )
    );

    return this.handleApiResponse(response.data);
  }

  async authorizeReservation(idReservation: number): Promise<string> {
    const response = await this.tryExecute(
      this.api.post<ApiResponse<string>>("reservations/finalize", {
        idReservation: idReservation,
      })
    );

    return this.handleApiResponse(response.data);
  }

  async calculateAmounts(
    request: CalculateAmountsRequest
  ): Promise<CalculateAmountsResponse> {
    const response = await this.tryExecute(
      this.api.post<ApiResponse<CalculateAmountsResponse>>(
        "reservations/CalculateAmounts",
        request
      )
    );

    return this.handleApiResponse(response.data);
  }

  async getReservation(
    idReservation: number,
    userEmail: string
  ): Promise<GetReservationResponse> {
    const response = await this.tryExecute(
      this.api.get<ApiResponse<GetReservationResponse>>(
        `reservations/${idReservation}/${userEmail}`
      )
    );

    return this.handleApiResponse(response.data);
  }
}

export default new ReservationService();
