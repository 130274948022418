import { DatiInizialiPartecipante } from "./WidgetOptions";

export class PartecipanteItem {
	ItemID: number;
	Cognome: string;
	Nome: string;
	CodiceFiscale: string;
	DataNascita: Date;
	Residenza: string;
	FasciaEta: number | null;

	constructor(dInizialiPartecipanti : DatiInizialiPartecipante, itemID: number) {
		this.ItemID = itemID || null;
		this.Nome = dInizialiPartecipanti ? dInizialiPartecipanti.nome || "" : "";
		this.Cognome = dInizialiPartecipanti ? dInizialiPartecipanti.cognome || "" : "";
		this.CodiceFiscale = dInizialiPartecipanti ? dInizialiPartecipanti.codiceFiscale || "" : "";
		this.DataNascita = dInizialiPartecipanti && dInizialiPartecipanti.dataNascita ? new Date(dInizialiPartecipanti.dataNascita ) : null;
		this.Residenza = dInizialiPartecipanti ? dInizialiPartecipanti.residenza || "" : "";
		this.FasciaEta = dInizialiPartecipanti ? dInizialiPartecipanti.fasciaEta || null : null;
	}
}

/*
export class Partecipante {
	itemID: number;
	cognome: string;
	nome: string;
	codiceFiscale: string;
	dataNascita: Date;
	residenza: string;
	fasciaEta: string;
}*/