<template>
    <div class="completion-steps">

        <div v-if="currentStep >= 2" class="completion-step">
            <span class="completion-step-title"> Polizza Selezionata </span>
            <span class="completion-step-btn" ><a class="webins-link" style="cursor: pointer"  @click="backTo(steps[1].step)">{{ t("common.stepEditLabel") }}</a></span
            >
            <table>
                <tr>
                    <!-- <td><svg data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M154.1 64.68c-.23-.71-.47-1.43-.73-2.13A60.39 60.39 0 0096.66 22.9h-.12A60.49 60.49 0 0053.62 126l39.6 49a5.66 5.66 0 008.89-.09l39.72-51.41a60.22 60.22 0 009.23-13.88 59.27 59.27 0 003.17-8 60.52 60.52 0 00-.13-36.94zm-8.84 12.91a49.43 49.43 0 01.31 7 50.53 50.53 0 01-1.54 11L125.47 70.2 145 75.35c.08.74.18 1.49.26 2.24zm-12.18 38.71l-35.55 46-35.21-43.57a6 6 0 00-.42-.46 49.16 49.16 0 0133.79-84h.89a49.16 49.16 0 0132.67 12.4 48.29 48.29 0 0113.06 18.89l-21.63-5.76 2.4-5.68a4.44 4.44 0 00-2.38-5.8l-13.25-4.48a4.43 4.43 0 00-5.6 2.77 4.43 4.43 0 002.78 5.6l8.46 2.86-10 23.81a25.74 25.74 0 00-5.8-.67 26.08 26.08 0 1013.93 4.11l4.46-10.6 24.58 34a43.01 43.01 0 01-1.71 3.12 50.41 50.41 0 01-5.47 7.46zm-40-10.37a4.36 4.36 0 002.37 2.4 4.42 4.42 0 005.79-2.35l6.46-15.34A17.22 17.22 0 1197.23 87a16.71 16.71 0 012.33.17l-6.46 15.38a4.36 4.36 0 00-.02 3.38z"/></svg></td> -->
                    <td>
                        <!-- <strong>Polizza Selezionata</strong><br /> -->
                        <p><strong>{{ rigaCarrelloDati.datiProdotto.pacchetto }}</strong></p>
                    </td>
                </tr>
            </table>
        </div>
        
        
        <hr v-if="currentStep >= 2" width="90%" size="1" color="#c3c3c3" noshade>

        <div class="completion-step">
            <!-- <span class="completion-step-title">{{ steps[0].desc }}</span> -->
            <span class="completion-step-btn"
                ><a class="webins-link" style="cursor:pointer" @click="backTo(steps[0].step)">{{ t("common.stepEditLabel") }}</a></span
            >
            
            <span>Date Viaggio</span>
            <div>
                <div class="d-flex">
                    <div class="summary-step-detail" >
                        <strong><p>{{ inizioViaggio }} - {{ fineViaggio }}</p></strong>
                    </div>
                </div>
            </div>

            <!-- <div style="display: grid; gap: 2em; width: 100%; grid-template-columns: 1fr 1.5fr"> OLD
                <div class="d-flex">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 4H8v.5a.5.5 0 01-1 0V4H5.5A1.5 1.5 0 004 5.5V8h16V5.5A1.5 1.5 0 0018.5 4H17v.5a.5.5 0 11-1 0V4zm1-1h1.5A2.5 2.5 0 0121 5.5v13.007a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5V5.5A2.5 2.5 0 015.5 3H7v-.5a.5.5 0 011 0V3h8v-.5a.5.5 0 111 0V3zm3 6H4v9.507a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5V9z"/></svg>
                    <div class="summary-step-detail" >
                        Date viaggio<br />
                        <strong><p>{{ inizioViaggio }} - {{ fineViaggio }}</p></strong>
                    </div>
                </div>
            </div> -->

            <div style="display: grid; gap: 2em; width: 100%; grid-template-columns: 1fr 1.5fr; margin-top: 15px">
                <div class="d-flex">
                    <!-- <svg data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M136.57 43.78a54.85 54.85 0 00-36.47-13.87h-.1a55 55 0 00-39 93.75l36 44.52a5.15 5.15 0 008.08-.09l36.11-46.73a55 55 0 00-4.62-77.58zm-3.36 71l-32.32 41.81L68.88 117a3.79 3.79 0 00-.38-.42 44.69 44.69 0 0130.71-76.35h.79a44.73 44.73 0 0129.7 11.27c18.39 16.3 19.95 44.71 3.51 63.31z"/><path d="M100 64.65a17.54 17.54 0 1017.55 17.56A17.58 17.58 0 00100 64.65zm7.24 17.55A7.24 7.24 0 11100 75a7.25 7.25 0 017.25 7.2z"/></svg> -->
                    <div class="summary-step-detail" >
                        {{ t("common.destination") }}<br />
                        <strong><p>{{ destinazioneNome }}</p></strong>
                    </div>
                </div>


                <div class="d-flex">
                    <!-- <svg viewBox="0 0 16 16" width="1em" height="1em" aria-label="people" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-people outline mr-3 b-icon bi text-black-lighten" cursor="pointer" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 017 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 01-.014.002H7.022zM11 7a2 2 0 100-4 2 2 0 000 4zm3-2a3 3 0 11-6 0 3 3 0 016 0zM6.936 9.28a5.88 5.88 0 00-1.23-.247A7.35 7.35 0 005 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 015 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 004 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 116 0 3 3 0 01-6 0zm3-2a2 2 0 100 4 2 2 0 000-4z"/></svg> -->
                    <div class="summary-step-detail" style="margin-left: 10px">
                        Viaggiatori<br />
                        <strong><p>{{ parametriPreventivatore.numeroPartecipanti || "-" }}</p></strong>
                    </div>
                    <!-- <div class="summary-step-detail"  style="margin-left: 10px" >
                        <template v-for="( part, i) in partecipantiPerFascia" :key="i">
                            <small >{{ part.descrizione }}: {{ part.numeroPartecipanti || "-" }} </small>
                            <br >
                        </template>
                    </div> -->
                </div>

               
            </div>
        </div>

        
        <hr v-if="currentStep >= 2 && totali" width="95%" size="1" color="#c3c3c3" noshade>
        

        <div class="completion-amount"  v-if="currentStep >= 2 && totali">
            <div class="text-theme-color">
                <strong>Totale: <span> {{ numberToCurrency( String(totali.totaleViaggio) ) }}</span></strong>
            </div>
            <div>
                di cui imposte: <span> {{ numberToCurrency( String(totali.totaleViaggioImposte)) }}</span>
            </div>
            <div v-if="totali.scontoCoupon">
                <small>Sconto applicato: <span> {{ numberToCurrency( String(totali.scontoCoupon)) }}</span></small>
            </div>


        </div>
    </div>
    <!-- 


    <div v-if="currentStep >= 3">
      <svg data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M77.48 96a5.69 5.69 0 00-8 8l17.7 17.7a5.65 5.65 0 008 0l35.4-35.4a5.69 5.69 0 00-8-8l-31.45 31.35zm42.85-67.51a5.68 5.68 0 00-4.4-2.09H84.08a5.7 5.7 0 00-4.41 2.09 66.51 66.51 0 01-30.76 21.1A5.68 5.68 0 0045 55v38.81a85.71 85.71 0 0052.81 79.37 5.67 5.67 0 004.38 0A85.71 85.71 0 00155 93.81V55a5.7 5.7 0 00-3.91-5.41 66.46 66.46 0 01-30.76-21.12zm23.29 65.31A74.39 74.39 0 01100 161.73 74.42 74.42 0 0156.38 93.8V59a77.72 77.72 0 0030.34-21.24h26.57A77.88 77.88 0 00143.62 59z"/></svg>
      <small>{{ t("common.extras") }}</small>
      <p>{{ reservation.selectedServices.length }} extra selezionati</p>
    </div>

    <div class="amount-bottom">
      <div v-if="currentStep >= 2">
        <small>{{ t("common.amountProducts") }} <span> € {{totalAmountProducts}}</span></small>
      </div>
      <div v-if="currentStep >= 3">
        <small>{{ t("common.amountExtras") }} <span> € {{totalAmountExtras}}</span></small>
      </div>
      <div v-if="reservation.totalDiscount > 0">
        {{ t("common.amountDiscount") }}
        <span> - € {{reservation.totalDiscount}}</span>
      </div>
      <div>
        {{ t("common.amountSubTotal") }}
        <span>€ {{reservation.totalAmount}}</span>
      </div>
    </div> -->
</template>

<script lang="ts" setup>
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { useNavigation, useWidget, usePolizze } from "@/composables";

const { t } = useI18n();
const { backTo, currentStep, steps } = useNavigation();

const { loading, parametriPreventivatore, totali, rigaCarrelloDati, numberToCurrency } = usePolizze();
const { destinazioniPreventivatore, elencoEta } = useWidget();

const partecipantiPerFascia = computed(() => {
    if( !parametriPreventivatore.value.fasceEta || !parametriPreventivatore.value.fasceEta.length )
        return [];

    var p = []
    for( var i = 0; i < parametriPreventivatore.value.fasceEta.length; i++){
        if(parametriPreventivatore.value.fasceEta[i].numeroPartecipanti )
            p.push(parametriPreventivatore.value.fasceEta[i]);
    }
    return p;
});


const inizioViaggio = computed(() => {
    if (parametriPreventivatore.value.inizioViaggio != null) {
        return dayjs(parametriPreventivatore.value.inizioViaggio).format(t("common.dateUIFormat") || "DD/MM/YYYY");
    }
    return "?";
});

const fineViaggio = computed(() => {
    if (parametriPreventivatore.value.fineViaggio != null) {
        return dayjs(parametriPreventivatore.value.fineViaggio).format(t("common.dateUIFormat") || "DD/MM/YYYY");
    }
    return "?";
});

const destinazioneNome = computed(() => {
    if (parametriPreventivatore.value.destinazioneStato != null) {
        var d = destinazioniPreventivatore.value.find((a) => a.value === parametriPreventivatore.value.destinazioneStato);
        return d && d.text ? d.text : "-";
    }
    return "-";
});

const eta = computed(() => {
    if (parametriPreventivatore.value.eta != null) {
        var d = elencoEta.value.find((a) => a.value === parametriPreventivatore.value.eta);
        return d && d.text ? d.text : "-";
    }
    return "-";
});
</script>
