<template>
    <div class="webins-reservation-summary">

        
        <div class="amount-top text-theme-color">Preventivo Assicurazione</div>
        
        <hr width="90%" size="1" color="#c3c3c3" noshade> 
         <div v-if="currentStep >= 2">
            
            <div class="summary-step-detail">
                Polizza <br />
                <strong><p>{{ rigaCarrelloDati.datiProdotto.pacchetto }}</p></strong>
            </div>
        </div>


        <div>
            <!-- <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 4H8v.5a.5.5 0 01-1 0V4H5.5A1.5 1.5 0 004 5.5V8h16V5.5A1.5 1.5 0 0018.5 4H17v.5a.5.5 0 11-1 0V4zm1-1h1.5A2.5 2.5 0 0121 5.5v13.007a2.5 2.5 0 01-2.5 2.5h-13a2.5 2.5 0 01-2.5-2.5V5.5A2.5 2.5 0 015.5 3H7v-.5a.5.5 0 011 0V3h8v-.5a.5.5 0 111 0V3zm3 6H4v9.507a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5V9z"/></svg> -->
            <div class="summary-step-detail">
                Date viaggio<br />
                <strong><p>{{ inizioViaggio }} - {{ fineViaggio }}</p></strong>
            </div>
        </div>


        <div>
            <!-- <svg data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M136.57 43.78a54.85 54.85 0 00-36.47-13.87h-.1a55 55 0 00-39 93.75l36 44.52a5.15 5.15 0 008.08-.09l36.11-46.73a55 55 0 00-4.62-77.58zm-3.36 71l-32.32 41.81L68.88 117a3.79 3.79 0 00-.38-.42 44.69 44.69 0 0130.71-76.35h.79a44.73 44.73 0 0129.7 11.27c18.39 16.3 19.95 44.71 3.51 63.31z"/><path d="M100 64.65a17.54 17.54 0 1017.55 17.56A17.58 17.58 0 00100 64.65zm7.24 17.55A7.24 7.24 0 11100 75a7.25 7.25 0 017.25 7.2z"/></svg> -->
            <div class="summary-step-detail">
                {{ t("common.destination") }}<br />
                <strong><p>{{ destinazioneNome }}</p></strong>
            </div>
        </div>



        <div style="padding-bottom: 10px">
            <!-- <svg viewBox="0 0 16 16" width="1em" height="1em" aria-label="people" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-people outline mr-3 b-icon bi text-black-lighten" cursor="pointer" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 017 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 01-.014.002H7.022zM11 7a2 2 0 100-4 2 2 0 000 4zm3-2a3 3 0 11-6 0 3 3 0 016 0zM6.936 9.28a5.88 5.88 0 00-1.23-.247A7.35 7.35 0 005 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 015 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 004 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 116 0 3 3 0 01-6 0zm3-2a2 2 0 100 4 2 2 0 000-4z"/></svg> -->
            <div class="summary-step-detail">
                Viaggiatori<br />
                <strong><p>{{ parametriPreventivatore.numeroPartecipanti || "-" }}</p></strong>
            </div>
            <!-- <div class="summary-step-detail" v-for="( part, i) in partecipantiPerFascia" :key="i" >
                <small>Fascia {{ part.descrizione }}:</small><br />
                <p>{{ part.numeroPartecipanti || "-" }} <span style="font-size:15px">{{ part.numeroPartecipanti === 1 ? 'partecipante' : 'partecipanti' }}</span> </p>
            </div> -->
        </div> 

        <div class="webins-amount-bottom" v-if="currentStep >= 2 && totali">
            <hr width="90%" size="1" color="#c3c3c3" noshade> 
            <span style="margin-bottom:10px">
                <div class="d-flex justify-content-between align-items-center text-theme-color">
                    <strong><span> Totale: </span></strong>
                    <strong><span>{{ numberToCurrency(String(totali.totaleViaggio)) }}</span></strong>
                </div>
                <div class="d-flex justify-content-between align-items-center" >
                    <small> di cui imposte:  </small>
                    <small>{{ numberToCurrency( String(totali.totaleViaggioImposte)) }}</small>
                </div>
                <div v-if="totali.scontoCoupon" class="d-flex justify-content-between align-items-center" >
                    <small> Sconto applicato:  </small>
                    <small>{{ numberToCurrency( String(totali.scontoCoupon)) }}</small>
                </div>
                
            
            </span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { useReservation, useNavigation, useWidget, usePolizze } from "@/composables";
import { ParametriPreventivatoreFasceEta } from "@/models/ParametriPreventivatore";

const { loading, parametriPreventivatore, totali, rigaCarrelloDati, numberToCurrency } = usePolizze();
const { destinazioniPreventivatore, elencoEta } = useWidget();

const { t } = useI18n();
const { modeCar } = useWidget();
const { currentStep } = useNavigation();


const partecipantiPerFascia = computed(() => {
    if( !parametriPreventivatore.value.fasceEta || !parametriPreventivatore.value.fasceEta.length )
        return [];

    var p = []
    for( var i = 0; i < parametriPreventivatore.value.fasceEta.length; i++){
        if(parametriPreventivatore.value.fasceEta[i].numeroPartecipanti )
            p.push(parametriPreventivatore.value.fasceEta[i]);
    }
    return p;
});

const inizioViaggio = computed(() => {
    if (parametriPreventivatore.value.inizioViaggio != null) {
        return dayjs(parametriPreventivatore.value.inizioViaggio).format(t("common.dateUIFormat") || "DD/MM/YYYY");
    }
    return "?";
});

const fineViaggio = computed(() => {
    if (parametriPreventivatore.value.fineViaggio != null) {
        return dayjs(parametriPreventivatore.value.fineViaggio).format(t("common.dateUIFormat") || "DD/MM/YYYY");
    }
    return "?";
});

const destinazioneNome = computed(() => {
    if (parametriPreventivatore.value.destinazioneStato != null) {
        var d = destinazioniPreventivatore.value.find((a) => a.value === parametriPreventivatore.value.destinazioneStato);
        return d && d.text ? d.text : "-";
    }
    return "-";
});



const eta = computed(() => {
    if (parametriPreventivatore.value.eta != null) {
        var d = elencoEta.value.find((a) => a.value === parametriPreventivatore.value.eta);
        return d && d.text ? d.text : "-";
    }
    return "-";
});
</script>
