import { Area } from "@/models";
import { BaseService } from "./BaseService";
import { ApiResponse } from "./responses/ApiResponse";
import { WidgetInitializationResponse } from "./responses/WidgetInitializationResponse";

class LookupService extends BaseService {
  async getAreas(): Promise<Area[]> {
    const response = await this.tryExecute(
      this.api.get<Area[]>("lookups/areas")
    );

    return response.data;
  }

  async initWidget(code: string): Promise<WidgetInitializationResponse> {
    const response = await this.tryExecute(
      this.api.get<ApiResponse<WidgetInitializationResponse>>(
        "lookups/widget/" + code
      )
    );

    return this.handleApiResponse(response.data);
  }
}

export default new LookupService();
