<template>
    <div>
       <svg
            class="bi-check text-green b-icon bi"
            viewBox="0 0 16 16"
            width="1.5em"
            height="1.5em"
            focusable="false"
            role="img"
            aria-label="check"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></g>
        </svg>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        title: {
            type: String,
            default: "test"
        }
    },
    setup() {
        return {};
    }
});
</script>
