<template>
    <div>
        <Tooltip ref="tooltip" :text="testoPopup" style="position: absolute;" 
        :style="{right: right ? (right + 'px') : '24px', top: top ? (top + 'px') : '-20px'}">
            <!-- <span> hover over me </span> -->
        </Tooltip>
        <svg
            viewBox="0 0 30 30"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="webinsInfoIcon"
            @click="showPopup"
        >
            <g >
                <!-- <path
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                ></path> -->

                <!-- <path transform="scale(1.4,1.4)" d="M3.35288 8.95043C4.00437 6.17301 6.17301 4.00437 8.95043 3.35288C10.9563 2.88237 13.0437 2.88237 15.0496 3.35288C17.827 4.00437 19.9956 6.17301 20.6471 8.95043C21.1176 10.9563 21.1176 13.0437 20.6471 15.0496C19.9956 17.827 17.827 19.9956 15.0496 20.6471C13.0437 21.1176 10.9563 21.1176 8.95044 20.6471C6.17301 19.9956 4.00437 17.827 3.35288 15.0496C2.88237 13.0437 2.88237 10.9563 3.35288 8.95043Z" fill="white" stroke="#61388D" stroke-width="1"/> -->
                
<path d="M16 2.672c-7.361 0-13.328 5.968-13.328 13.328 0 7.362 5.968 13.328 13.328 13.328s13.328-5.966 13.328-13.328c0-7.361-5.968-13.328-13.328-13.328zM16 28.262c-6.761 0-12.262-5.501-12.262-12.262s5.5-12.262 12.262-12.262c6.761 0 12.262 5.501 12.262 12.262s-5.5 12.262-12.262 12.262z" fill="#000000">

</path>
                <ellipse transform="translate(12 8)" cx="4.486" cy="1.525" rx="1.486" ry="1.525"></ellipse>
                <path transform="translate(12 8)" d="M4.479,13.325 L5.852,4.703 C5.852,4.703 5.881,4.004 5.216,4.004 C3.715,4.004 -0.074,4.008 -0.074,4.008 C-0.074,4.008 3.905,4.721 3.905,6.067 C3.905,6.067 2.597,11.827 2.597,13.357 C2.597,14.888 3.433,16.001 4.934,16.001 C6.159,16.001 7.272,14.665 7.188,12.718 C5.991,14.554 4.479,14.82 4.479,13.325 L4.479,13.325 Z" ></path>
            </g>
        </svg>

       


    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from "vue";
import Tooltip from "@/components/tooltip.vue";

const tooltip = ref(null);

const props =
    defineProps<{
        title?: string;
        testoPopup?: string;
        right?: number;
        top?: number;
    }>();

const showPopup = () => {
    if (props.testoPopup) 
        tooltip.value.show();
};
</script>

<style scoped>
.webinsInfoIcon {
    color: #61388D;
    display: inline-block;
    overflow: visible;
    /*vertical-align: -0.15em;*/
    cursor: pointer;
}
</style>
