
export class erroriDatiPolizza {
	aderenteNome: boolean;
	aderenteCognome: boolean;
	aderenteIndirizzo: boolean;
	aderenteCap: boolean;
	aderenteResidenza: boolean;
	aderenteCitta: boolean;
	aderenteCodiceProvincia: boolean;
	aderenteCodiceFiscale: boolean;
	aderenteDataNascita: boolean;
	aderenteTelefono: boolean;
	aderenteEmail: boolean;
	aderenteConfermaEmail: boolean;

	partecipanti: erroriDatiPartecipanti[];
}

export class erroriDatiPartecipanti {
	nome: boolean;
	cognome: boolean;
	residenza: boolean;
	dataNascita: boolean;
	codiceFiscale: boolean;
}