<template>
    <div class="webins-collapsible" v-if="visible">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick } from "vue";

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        return { }
    }
});
</script>