import { ref, watch, computed, defineComponent, onMounted } from "vue";
import { useReservation, useWidget } from "./";

const currentStep = ref(0);

export function useNavigation() {
  const { reservation } = useReservation();
  const { options, forcedPickPoint, widgetConfig } = useWidget();

  const steps = ref([]);

  /*
  if (options.value.scope == 1) {
    steps.value = [
      { step: 0, desc: "Dove e quando" },
      { step: 1, desc: "Pick Point" },
      { step: 2, desc: "eBike" },
      { step: 3, desc: "Extra" },
      { step: 4, desc: "Dati personali" },
      { step: 5, desc: "Pagamento" },
      { step: 6, desc: "Conferma" },
    ];
  } else {
    steps.value = [
      { step: 0, desc: "Dati Viaggio" },
      { step: 1, desc: "Scegli Polizza" },
      { step: 2, desc: "Viaggiatori" },
      { step: 3, desc: "Pagamento" },
      { step: 4, desc: "Conferma" },
    ];
  }*/
  if(widgetConfig.value.abilitaAggiuntaCarrello){
    steps.value = [
      { step: 0, desc: "Dati Viaggio", disabled: true }, // non usato
      { step: 1, desc: "Scegli Polizza" },
      { step: 2, desc: "Viaggiatori", disabled: true }, // non usato
      { step: 3, desc: "Riepilogo" },
      { step: 4, desc: "Conferma" },
    ];
  }
  else{
    steps.value = [
      { step: 0, desc: "Dati Viaggio" },
      { step: 1, desc: "Scegli Polizza" },
      { step: 2, desc: "Viaggiatori" },
      { step: 3, desc: "Pagamento" },
      { step: 4, desc: "Conferma" },
    ];
  }

  const getImageThumbPath = (imgPath: string, size: number) => {
    if (imgPath != null) {
      return `${process.env.VUE_APP_SERVER_PUBLIC_PATH}c/${size}/${size}/${imgPath}`;
    }
  };


  const nextWebinsStep = () => {
    switch (currentStep.value) {
      case 0: // preventivatore
        goTo(currentStep.value + 1);
        break;
      case 1: // risultati ricerca
        if(widgetConfig.value && widgetConfig.value.abilitaAggiuntaCarrello)
          goTo(3);
        else
          goTo(currentStep.value + 1);
        break;
      case 2: { // dati partecipanti
        goTo(currentStep.value + 1);
        break;
      }
      case 3: // pagamento
        goTo(currentStep.value + 1);
        break;
      case 4: // conferma
        goTo(currentStep.value + 1);
        break;
    }
  };



  const next = () => {
    // TODO: selezione su id scope debole. Gestire una tipologia widget
    nextWebinsStep();
  };

  const prev = () => {
      //
  };

  const backTo = (step: number) => {
    if (step >= 0 && step < currentStep.value && currentStep.value != 4) {
      const stepItem = steps.value.find((s) => s.step == step);


      const indexCurrent = steps.value.findIndex((s) => s.step == currentStep.value);
      const indexDest = steps.value.findIndex((s) => s.step == step);
      let stepsBack2 = 0;
      for(let i = indexDest ; i < indexCurrent; i++){
        if(!steps.value[i].disabled)
          stepsBack2--;
      }

      //const stepBack = step - currentStep.value;
      window.history.go(stepsBack2);
      
      if (options && options.value && options.value.stepActivatedCallback) {
        options.value.stepActivatedCallback(step, stepItem.desc);
      }
    }
  };

  const goTo = (step: number) => {
    if (step >= 0 && step <= 6) {
      const stepItem = steps.value.find((s) => s.step == step);
      history.pushState({ step: stepItem.step }, "Booking - " + stepItem.desc);
      currentStep.value = step;

      if (options && options.value && options.value.stepActivatedCallback) {
        options.value.stepActivatedCallback(step, stepItem.desc);
      }
    }
  };

  window.onpopstate = function (event: { state: { step: number } }) {
    if (event.state != null) {
      currentStep.value = event.state.step;
    }
  };

  return {
    getImageThumbPath,
    steps,
    currentStep,
    next,
    prev,
    goTo,
    backTo,
  };
}
