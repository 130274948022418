<template>
    <table class="webins-riga-riepilogo">
        <thead>
            <tr>
                <th :colspan="showMainMedia ? 2 : 1">Polizza</th>
                <th style="width: 70px"  v-if="totali && totali.scontoCoupon">Sconto applicato</th>
                <th style="width: 70px">Imposte</th>
                <th style="width: 70px">Premio</th>
                <th style="width: 34px" v-if="enableDel == true">&nbsp;</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="prod in prodotti" :key="prod.datiProdotto.pacchettoID">
                <td style="width: 50px; padding-left: 0; padding-right: 0" v-if="showMainMedia">
                    <img v-if="prod.mainMedia" :src="getImageThumbPath(prod.mainMedia.path, 50)" />
                </td>
                <td>
                    {{ prod.datiProdotto.pacchetto }}
                    <br>
                    <a href="#" class="webins-link" style="width: 90%; border-radius: 10px" @click.prevent="getTerminiECondizioni(prod.datiProdotto)">
                        Termini e Condizioni
                    </a>
                    <br v-if="enableDetailButton" />
                    <a v-if="enableDetailButton" class="webins-link" href="#" @click="showDetail()">Vedi dettagli</a>
                </td>
                <td v-if="totali && totali.scontoCoupon">
                    <span v-if="totali">{{ numberToCurrency( String(totali.scontoCoupon )) }}</span>
                </td>
                <td>
                    <span v-if="totali">{{ numberToCurrency( String(totali.totaleViaggioImposte )) }}</span>
                </td>
                <td>
                    <span v-if="totali">{{ numberToCurrency( String(totali.totaleViaggio )) }}</span>
                </td>
                
               
            </tr>
        </tbody>
    </table>

    <div class="total">
        <div>Totale {{ numberToCurrency( String(totali.totaleViaggio )) }}</div>
    </div>

    <Popup ref="popup">
       <div v-html="popupContent"></div>
    </Popup>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";
import { useReservation, useNavigation, useWidget, usePolizze } from "@/composables";
import Popup from "../../popup.vue";
import WebinsService from "@/services/WebinsService";
import { RigaCarrelloDati } from "@/models/RigaCarrello";

const props =
    defineProps<{
        enableDel?: boolean;
        enableDetailButton?: boolean;
        showMainMedia?: boolean;
    }>();



const { getImageThumbPath } = useNavigation();
const { reservation } = useReservation();
const { loading, totali, rigaCarrelloDati, numberToCurrency, getTerminiECondizioni } = usePolizze();
const {  } = useWidget();

const descrizioneCommerceBreve = ref("");
const popup = ref();

var prodotti = ref<RigaCarrelloDati[]>([]); 

prodotti.value.push(rigaCarrelloDati.value);

onMounted( async() => {
    if (!descrizioneCommerceBreve.value)
        descrizioneCommerceBreve.value = await WebinsService.getDescrizioneCommerceBreve(rigaCarrelloDati.value.datiCarrello.pacchettoID);
});

const showDetail = () => {
    popup.value.show();
}

const popupContent = computed( () => {    
    return descrizioneCommerceBreve.value;
});

</script>