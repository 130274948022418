<template>
    <div class="">
        <template v-if="rightFieldType == 1">
            <IconCheck v-if="checkedTipo1"></IconCheck>
            <IconX v-else></IconX>
        </template>

        <template v-if="rightFieldType == 2">
            <IconX v-if="prodottoParametro == null || prodottoParametro.valore == null || prodottoParametro.valore == ''"></IconX>
            <div v-else-if="(prodottoParametro.valore == parametro.valore) == '-1'">Illimitato</div>
            <div v-else>{{ numberToPercentage(prodottoParametro.valore, prodottoParametro.decimalSlot || 0) }}</div>
        </template>

        <template v-if="rightFieldType == 3">
            <IconX v-if="prodottoParametro == null || prodottoParametro.valore == null || prodottoParametro.valore == ''"></IconX>
            <div v-else-if="(prodottoParametro.valore == parametro.valore) == '-1'">Illimitato</div>
            <div v-else>{{ numberToCurrency(prodottoParametro.valore, 0) }}</div>
        </template>
        <template v-if="rightFieldType == 4">
            <div v-if="checkedTipo4">{{ numberToDecimal(prodottoParametro.valore, prodottoParametro.decimalSlot || 0) }}</div>
            <IconX v-else></IconX>
        </template>

        <template v-if="rightFieldType == 5">
            <div v-if="checkedTipo5">{{ numberToInteger(prodottoParametro.valore) }}</div>
            <IconX v-else></IconX>
        </template>

        <template v-if="rightFieldType == 6">
            <div v-if="checkedTipo6">{{ checkedTipo6 }}</div>
            <IconX v-else></IconX>
        </template>

        <template v-if="rightFieldType == 7">
            <div v-if="checkedTipo7">{{ checkedTipo7 }}</div>
            <IconX v-else></IconX>
        </template>
        <template v-if="rightFieldType == -1">
            <div v-if="checkedTipoText" v-html="checkedTipoText"></div>
            <IconX v-else></IconX>
        </template>
    </div>
</template>

<script lang="ts">
import { AssociaDistributoriPacchettiExtended, schedaProdotto } from "@/models/AssociaDistributoriPacchetti";
import { computed, defineComponent, ref, PropType } from "vue";
import IconX from "@/components/icon-x.vue";
import IconCheck from "@/components/icon-check.vue";
import { useI18n } from "vue-i18n";
import { usePolizze } from "@/composables";
export default defineComponent({
    components: { IconCheck, IconX },
    props: {
        parametro: {
            type: Object as PropType<schedaProdotto>,
            default: null
        },
        prodotto: {
            type: Object as PropType<AssociaDistributoriPacchettiExtended>,
            default: null
        }
    },
    setup(props) {
        const { t } = useI18n();

        const { numberToPercentage, numberToCurrency, numberToDecimal, numberToInteger } = usePolizze();
        
        const prodottoParametro = computed(() => {
            if (props.parametro == null || props.prodotto == null || (props.prodotto as AssociaDistributoriPacchettiExtended ).parametri == null || (props.prodotto as AssociaDistributoriPacchettiExtended ).parametri.length == 0) {
                return null;
            }

            var p = (props.prodotto as AssociaDistributoriPacchettiExtended ).parametri.find((x) => x.categoria == (props.parametro as schedaProdotto).categoria && x.nomeParametro == (props.parametro as schedaProdotto).nomeParametro);
            return p;
        });

        const rightFieldType = computed(() => {
            if (prodottoParametro.value && (prodottoParametro.value as any).fieldType == -1) return (prodottoParametro.value as any).fieldType;

            return (props.parametro as any).fieldType;
        });

        const checkedTipo1 = computed(() => {
            var result = false;
            if (prodottoParametro.value) {
                result = prodottoParametro.value.valore == "1";
            }
            return result;
        });

        const checkedTipo2 = computed(() => {
            var result = "";
            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                if (parseInt(parametro.valore) > 0) {
                    result = "€ " + parametro.valore;
                } else if (parametro.valore == "-1") {
                    result = "Illimitato";
                }
            }
            return result;
        });

        const checkedTipo3 = computed(() => {
            var result = "";
            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                result = parametro.valoreTesto;
            }
            return result;
        });

        const checkedTipo4 = computed(() => {
            var result = "";
            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                return parametro.valore || parametro.valore == "0";
                // switch (parametro.valore) {
                // 	case "1":
                // 		result = "Entro 70";
                // 	case "2":
                // 		result = "Entro 70 e 75";
                // 	case "3":
                // 		result = "Entro 75 e 90";
                // 	case "4":
                // 		result = "Oltre 90";
                // }
            }
            return result;
        });

        const checkedTipo5 = computed(() => {
            var result = "";
            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                return parametro.valore || parametro.valore == "0";
                // switch (parametro.valore) {
                // 	case "1":
                // 		result = "Individiale";
                // 	case "2":
                // 		result = "Gruppo";
                // }
            }
            return result;
        });

        const checkedTipo6 = computed(() => {
            var result = "";

            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                return parametro.valoreTesto;
            }
            return result;
        });

        const checkedTipo7 = computed(() => {
            var result = "";
            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                return parametro.valoreTesto;
            }
            return result;
        });

        const checkedTipoText = computed(() => {
            var result = "";
            var parametro = prodottoParametro.value;
            if (parametro && parametro.valore) {
                return parametro.valoreTesto;
            }
            return result;
        });

        return {
            t,
            prodottoParametro,
            rightFieldType,
            checkedTipo1,
            checkedTipo2,
            checkedTipo3,
            checkedTipo4,
            checkedTipo5,
            checkedTipo6,
            checkedTipo7,
            checkedTipoText,
            numberToPercentage,
            numberToCurrency,
            numberToDecimal,
            numberToInteger
        };
    }
});
</script>
