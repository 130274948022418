<template>
    <div class="webins-widget-step-cols webins-step-dati-partecipanti">
        <div class="availabilities-panel">
            <div class="d-flex" v-if="!nascondiContraente">
                <span class="d-flex" style="margin-bottom:10px">
                    <span style="font-size:20px; font-weight:bold">Dati del Contraente</span>
                    <IconInfo :top="-15" :right="14" :testoPopup="textInfoContraente" style="position:relative; margin-left: 5px; margin-top: -5px; width: 30px"></IconInfo>
            
                </span>
                <!-- <h2>Dati del Contraente</h2>
                <IconInfo :title="textInfoContraente" :testoPopup="textInfoContraente" style="margin-left: 5px; margin-top: -5px; width: 30px"></IconInfo>
             -->
            </div>
            <div class="pax-box" v-if="!nascondiContraente">
                <div class="pax-data-form">
                    <div>
                        <CustomInput :placeHolder="'Nome'" :type="'string'" v-model:value="aderente.nome" :errore="errori.aderenteNome"></CustomInput>
                    </div>
                    <div>
                        <CustomInput :placeHolder="'Cognome'" :type="'string'" v-model:value="aderente.cognome" :errore="errori.aderenteCognome"></CustomInput>
                    </div>

                    <div>
                        <CustomInput
                            :placeHolder="'Residenza'"
                            :type="'select'"
                            :optionLabel="'Seleziona un\'opzione'"
                            :disabled="!mostraNazionalita"
                            :dataSource="residenze"
                            :valueField="'code'"
                            :errore="errori.aderenteResidenza"
                            v-model:value="aderente.residenza"
                        ></CustomInput>
                    </div>

                    <div>
                        <CustomInput :placeHolder="'Data di nascita'" :type="'date'" :errore="errori.aderenteDataNascita" v-model:value="dataAderenteModel"></CustomInput>
                    </div>

                    <div v-if="aderente.residenza === 'italia'">
                        <CustomInput :placeHolder="'Codice Fiscale'" :type="'string'" v-model:value="aderente.codiceFiscale" :errore="errori.aderenteCodiceFiscale"></CustomInput>
                    </div>

                    <div>
                        <CustomInput
                            :placeHolder="'Provincia'"
                            :type="'select'"
                            :optionLabel="'Seleziona un\'opzione'"
                            :valueField="'code'"
                            :errore="errori.aderenteCodiceProvincia"
                            :dataSource="province"
                            v-model:value="aderente.codiceProvincia"
                        ></CustomInput>
                    </div>

                    <div>
                        <CustomInput :placeHolder="'Citta'" :type="'string'" v-model:value="aderente.citta" :errore="errori.aderenteCitta"></CustomInput>
                    </div>

                    <div>
                        <CustomInput :placeHolder="'Cap'" :type="'string'" v-model:value="aderente.cap" :errore="errori.aderenteCap"></CustomInput>
                    </div>

                    <div>
                        <CustomInput :placeHolder="'Indirizzo'" :type="'string'" v-model:value="aderente.indirizzo" :errore="errori.aderenteIndirizzo"></CustomInput>
                    </div>

                    <div>
                        <CustomInput :placeHolder="'Telefono'" :type="'string'" v-model:value="aderente.telefono" :errore="errori.aderenteTelefono"></CustomInput>
                    </div>
                    <div>
                        <CustomInput :placeHolder="'Email'" :type="'string'" email v-model:value="aderente.email" :errore="errori.aderenteEmail"></CustomInput>
                    </div>

                    <div>
                        <CustomInput :placeHolder="'Conferma Email'" :type="'string'" email v-model:value="aderente.confermaEmail" :errore="errori.aderenteConfermaEmail"></CustomInput>
                    </div>
                </div>
                <div class="d-flex align-items-center" style="margin-top: 10px">
                    <input type="checkbox" v-model="aderentePartecipante" @change="onChangeCheckAderentePartecipante" />
                    <strong class="checkboxtext">Il contraente fa parte degli assicurati</strong>
                </div>
            </div>

            <h2>Dati dei viaggiatori</h2>

            <div v-for="(pax, i) in partecipanti" :key="i" class="pax-box">
                <div>
                    <h3>
                        <strong>Viaggiatore {{ i + 1 }}</strong>
                    </h3>
                </div>
                <div class="pax-data-form" style="margin-top: 20px">
                    <div>
                        <CustomInput :placeHolder="'Nome'" :type="'string'" :disabled="i === 0 && aderentePartecipante" v-model:value="pax.Nome" :errore="errori.partecipanti[i].nome"></CustomInput>
                    </div>
                    <div>
                        <CustomInput
                            :placeHolder="'Cognome'"
                            :type="'string'"
                            :disabled="i === 0 && aderentePartecipante"
                            v-model:value="pax.Cognome"
                            :errore="errori.partecipanti[i].cognome"
                        ></CustomInput>
                    </div>

                    <div>
                        <CustomInput
                            :placeHolder="'Data di nascita'"
                            :type="'date'"
                            :disabled="i === 0 && aderentePartecipante"
                            @updateValue="handleInputDataNascita($event, i)"
                            v-model:value="dataAssicuratiModel[i]"
                            :errore="errori.partecipanti[i].dataNascita"
                        ></CustomInput>

                        <!-- <label><small>Data di nascita</small></label>
                        <input type="date" @input="handleInputDataNascita($event, i)" :value="dataAssicuratiModel[i]" :disabled="i === 0 && aderentePartecipante" /> -->
                    </div>

                    <div>
                        <CustomInput
                            :placeHolder="'Residenza'"
                            :type="'select'"
                            :optionLabel="'Seleziona un\'opzione'"
                            :disabled="!mostraNazionalita || (i === 0 && aderentePartecipante)"
                            :dataSource="residenze"
                            :valueField="'code'"
                            v-model:value="pax.Residenza"
                            :errore="errori.partecipanti[i].residenza"
                        ></CustomInput>
                    </div>
                    <div v-if="pax.Residenza === 'italia'">
                        <CustomInput
                            :placeHolder="'Codice Fiscale'"
                            :type="'string'"
                            :disabled="i === 0 && aderentePartecipante"
                            v-model:value="pax.CodiceFiscale"
                            :errore="errori.partecipanti[i].codiceFiscale"
                        ></CustomInput>
                    </div>
                </div>
            </div>

            <div style="margin: 0 auto">
                <div class="d-flex" style="margin-top: 10px; align-items: flex-start" v-if="!widgetConfig.nascondiFlagTermini">
                    <!-- <label class="d-flex align-items-center"> -->
                    <input type="checkbox" name="checkbox" v-model="checkPolizza" />
                    <span class="checkboxtext" style="width: 100%"
                        >Confermo di aver letto, compreso ed accettato i
                        <a class="webins-link" style="cursor: pointer" @click="getDocumentoPolizza(rigaCarrelloDati.datiProdotto, 1)"> termini delle Condizioni di Polizza </a>, il
                        <a class="webins-link" style="cursor: pointer" @click="getTerminiECondizioni(rigaCarrelloDati.datiProdotto)"> Set Informativo </a> e gli
                        <a class="webins-link" style="cursor: pointer" @click="getDocumentoPolizza(rigaCarrelloDati.datiProdotto, 8)"> allegati 3, 4, 4 ter</a>.
                    </span>
                    <!-- </label> -->
                </div>
                <!-- <div style="margin-top: 8px">
                    <a class="webins-link" style="cursor: pointer" @click="getTerminiECondizioni( rigaCarrelloDati.datiProdotto)"> Leggi i termini delle Condizioni di Polizza</a>
                </div> -->

                <div class="d-flex" style="margin-top: 20px; align-items: flex-start" v-if="!widgetConfig.nascondiFlagPrivacy">
                    <!-- <label class="d-flex align-items-center"> -->
                    <input type="checkbox" name="checkbox" v-model="checkPrivacy" />
                    <span class="checkboxtext" style="width: 100%"
                        >Confermo di aver letto
                        <a class="webins-link" style="cursor: pointer" @click="getDocumentoPolizza(rigaCarrelloDati.datiProdotto, 11)">Privacy Policy</a>
                        e presto il consenso al trattamento dei dati personali per l’adesione alla presente polizza.</span
                    >
                    <!-- <span class="checkboxtext" style="width: 100%"
                        >Confermo di aver letto
                        <a class="webins-link" href="https://webins.it/privacy-policy" target="_blank">Privacy Policy</a>
                        e presto il consenso al trattamento dei dati personali per l’adesione alla presente polizza.</span
                    > -->
                    
                    <!-- </label> -->
                </div>

                <div class="d-flex" style="margin-top: 20px; align-items: flex-start" v-if="!nascondiContraente && !widgetConfig.nascondiFlagConsensoMarketing">
                    <!-- <label class="d-flex align-items-center"> -->
                    <input type="checkbox" name="checkbox" v-model="aderente.consensoMarketing" />
                    <span class="checkboxtext" style="width: 100%" v-html="testoConsensoMarketing"></span>
                    <!-- </label> -->
                </div>

                <div class="d-flex" style="margin-top: 20px; align-items: flex-start" v-if="!widgetConfig.nascondiFlagSicurezza">
                    <!-- <label class="d-flex align-items-center"> -->
                    <input type="checkbox" name="checkbox" v-model="checkSicurezza" />
                    <span class="checkboxtext" style="width: 100%" v-html="testoSicurezza"></span>
                    <!-- </label> -->
                </div>

                <!-- 
                <div style="margin-top: 10px">
                    <a class="webins-link" href="https://webins.it/privacy-policy" target="_blank">Leggi il Documento di Privacy Policy</a>
                </div> -->
            </div>

            <div class="" style="margin-top: 30px">
                <!-- <button class="btn btn-secondary" @click="prevStep">Torna indietro</button> -->
                <button class="btn" @click="onAggiungiACarrello">Conferma</button>
                <!-- <button class="btn" @click="validaFasceEta">Valida Fasce</button> -->
            </div>
        </div>
        <div>
            <SummaryRisultati />
        </div>
    </div>
    <Popup ref="popupAlert">
        <div style="font-weight: bold" v-html="alertText"></div>
    </Popup>
    <Loading :isloading="loading" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Loading from "../../loading.vue";
import CustomInput from "@/components/custom-input.vue";
import IconInfo from "@/components/icon-info-positional.vue";
import SummaryRisultati from "../../summary-risultati.vue";
//import FormPartecipanti from "../datiDeiPartecipanti/form-partecipanti.vue";
import { useNavigation, usePolizze, useWidget } from "@/composables";
import { datiAderente } from "@/models/DatiAderente";
import { PartecipanteItem } from "@/models/Partecipante";
import Popup from "@/components/popup.vue";
import dayjs from "dayjs";
import { Comune } from "@/models/Province";
import { TextValueItem } from "@/models/TextValueItem";
import { erroriDatiPartecipanti, erroriDatiPolizza } from "@/models/ErroriDatiPartecipanti";

const { t } = useI18n();

const errori = ref<erroriDatiPolizza>(new erroriDatiPolizza());

const aderente = ref<datiAderente>(new datiAderente());
const aderentePartecipante = ref<boolean>(false);
const textInfoContraente = ref<string>("Il contraente è la persona che stipula la polizza assicurativa per proteggere se stesso e/o gli altri viaggiatori assicurati con la medesima polizza.");

const partecipanti = ref<PartecipanteItem[]>([]);
const comuniFiltered = ref<Comune[]>([]);

const popupAlert = ref(null);
const alertText = ref("");
const usaFasce = ref<boolean>(false);
const nascondiContraente = ref<boolean>(false);

//const adeguatezza = ref<boolean>(false);
const checkPolizza = ref<boolean>(false);
const checkPrivacy = ref<boolean>(false);
const checkSicurezza = ref<boolean>(false);

const { loading, rigaCarrelloDati, parametriPreventivatore, getTerminiECondizioni, getDocumentoPolizza } = usePolizze();
const { datiIniziali, province, comuni, elencoFasceEta, widgetConfig } = useWidget();
const { next } = useNavigation();

const residenze = ref<TextValueItem[]>([
    { code: "italia", text: "Italia" },
    { code: "estero", text: "Estero" }
]);

const dataAssicuratiModel = ref<Date[]>([]);

onMounted(() => {
    usaFasce.value = widgetConfig.value && widgetConfig.value.usaFasceEta ? true : false;
    nascondiContraente.value = widgetConfig.value && widgetConfig.value.nascondiContraente ? true : false;
    initErrori();

    for (var i = 0; i < parametriPreventivatore.value.numeroPartecipanti; i++) {
        dataAssicuratiModel.value.push(null);
        partecipanti.value.push({
            ItemID: i + 1,
            Nome: "",
            Cognome: "",
            DataNascita: null,
            CodiceFiscale: "",
            Residenza: "",
            FasciaEta: null
        });
    }

    var dInizialiAderente = rigaCarrelloDati.value.datiCarrello.aderente || (datiIniziali && datiIniziali.value && datiIniziali.value.datiContraente ? datiIniziali.value.datiContraente : null);

    if (dInizialiAderente) {
        aderente.value = {
            cognome: dInizialiAderente.cognome || "",
            nome: dInizialiAderente.nome || "",
            email: dInizialiAderente.email || "",
            confermaEmail: "",
            indirizzo: dInizialiAderente.indirizzo || "",
            telefono: dInizialiAderente.telefono || "",
            residenza: dInizialiAderente.residenza || "",
            cap: dInizialiAderente.cap || "",
            comune: dInizialiAderente.comune || "",
            citta: dInizialiAderente.citta || "",
            codiceProvincia: dInizialiAderente.codiceProvincia || "",
            codiceFiscale: dInizialiAderente.codiceFiscale || "",
            dataNascita: dInizialiAderente.dataNascita ? new Date(dInizialiAderente.dataNascita) : null,
            consensoMarketing: false
        };
    }

    var dInizialiPart: PartecipanteItem[] = [];

    if (rigaCarrelloDati.value.datiCarrello.elencoPartecipanti) {
        try {
            var p = JSON.parse(atob(rigaCarrelloDati.value.datiCarrello.elencoPartecipanti));
            p && p.length && (dInizialiPart = p);

            if (dInizialiPart && dInizialiPart.length) {
                for (var i = 0; i < dInizialiPart.length; i++) dInizialiPart[i].DataNascita = dInizialiPart[i].DataNascita ? new Date(dInizialiPart[i].DataNascita) : null;
            }
        } catch (e) {}
    }

    if (!dInizialiPart.length && datiIniziali && datiIniziali.value && datiIniziali.value.assicurati && datiIniziali.value.assicurati.length) {
        for (var i = 0; i < datiIniziali.value.assicurati.length; i++) {
            dInizialiPart.push(new PartecipanteItem(datiIniziali.value.assicurati[i], i + 1));
        }
    }

    if (dInizialiPart && dInizialiPart.length) {
        for (var i = 0; i < dInizialiPart.length; i++) {
            if (i >= rigaCarrelloDati.value.datiCarrello.numeroPartecipanti) break;

            partecipanti.value[i].ItemID = dInizialiPart[i].ItemID || i + 1;
            partecipanti.value[i].Nome = dInizialiPart[i].Nome || "";
            partecipanti.value[i].Cognome = dInizialiPart[i].Cognome || "";
            partecipanti.value[i].CodiceFiscale = dInizialiPart[i].CodiceFiscale || "";
            partecipanti.value[i].DataNascita = dInizialiPart[i].DataNascita || null;
            partecipanti.value[i].Residenza = dInizialiPart[i].Residenza || "";
            //partecipanti.value[i].FasciaEta = dInizialiPart[i].FasciaEta || "" ;

            //partecipanti.value[i].DataNascita && (dataAssicuratiModel.value[i] = dayjs(partecipanti.value[i].DataNascita).format("YYYY-MM-DD"));
            partecipanti.value[i].DataNascita && (dataAssicuratiModel.value[i] = dayjs(partecipanti.value[i].DataNascita).toDate());
        }
    }

    if (!mostraNazionalita.value) {
        aderente.value.residenza = "italia";

        for (var i = 0; i < partecipanti.value.length; i++) {
            partecipanti.value[i].Residenza = "italia";
        }
    }

    if (rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante) {
        aderentePartecipante.value = true;
        updatePrimoPartecipanteAderente();
    }

    updateComuni();
});

const mostraNazionalita = computed(() => {
    return rigaCarrelloDati.value.datiProdotto.mostraNazionalita ? true : false;
});

const objTestoTerminiFlag = computed(() => {
    if( widgetConfig.value.testoFlagTermini){
        return "";
    }
    else{
        return ""
    }
});

const testoConsensoMarketing = computed(() => {
    if( widgetConfig.value.testoFlagConsensoMarketing){
        return  widgetConfig.value.testoFlagConsensoMarketing;
    }
    else{
        return "Presto il consenso al trattamento dei miei dati personali per attività promozionale, pubblicitaria, commerciale e marketing da parte di Wondersafe.it."
    }
});

const testoPrivacy = computed(() => {
    if( widgetConfig.value.testoFlagPrivacy){
        return  widgetConfig.value.testoFlagPrivacy;
    }
    else{
        return "Dichiaro che i viaggiatori sono consapevoli che la polizza non offre coperture per viaggi verso Paesi in stato di belligeranza."
    }
});

const testoSicurezza = computed(() => {
    if( widgetConfig.value.testoFlagSicurezza){
        return  widgetConfig.value.testoFlagSicurezza;
    }
    else{
        return "Dichiaro che i viaggiatori sono consapevoli che la polizza non offre coperture per viaggi verso Paesi in stato di belligeranza."
    }
});

const dataAderenteModel = computed({
    get(): string {
        return aderente.value.dataNascita ? dayjs(aderente.value.dataNascita).format("YYYY-MM-DD") : "";
    },
    set(newValue: string): void {
        aderente.value.dataNascita = dayjs(newValue).toDate();
    }
});

watch(
    () => [aderente.value],
    () => {
        if (aderentePartecipante.value) updatePrimoPartecipanteAderente();
        aggiornaDatiCarrello();
    },
    { deep: true }
);

watch(
    () => [partecipanti.value],
    () => {
        aggiornaDatiCarrello();
    },
    { deep: true }
);

const onChangeCheckAderentePartecipante = (e) => {
    if (aderentePartecipante.value) updatePrimoPartecipanteAderente();
};

const updatePrimoPartecipanteAderente = () => {
    partecipanti.value[0].Nome = aderente.value.nome;
    partecipanti.value[0].Cognome = aderente.value.cognome;
    partecipanti.value[0].DataNascita = aderente.value.dataNascita;
    partecipanti.value[0].Residenza = aderente.value.residenza;
    partecipanti.value[0].CodiceFiscale = aderente.value.codiceFiscale;

    //dataAssicuratiModel.value[0] = partecipanti.value[0].DataNascita ? dayjs(partecipanti.value[0].DataNascita).format("YYYY-MM-DD") : "";
    dataAssicuratiModel.value[0] = partecipanti.value[0].DataNascita ? dayjs(partecipanti.value[0].DataNascita).toDate() : null;
};

const updateComuni = () => {
    if (!aderente.value.codiceProvincia) {
        comuniFiltered.value = [];
    } else {
        //var currComune = aderente.value.comune;
        comuniFiltered.value = comuni.value.filter((obj) => {
            return obj.codiceProvincia === aderente.value.codiceProvincia;
        });
    }
};

const aggiornaDatiCarrello = () => {
    if (nascondiContraente.value) {
        rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante = null;
        rigaCarrelloDati.value.datiCarrello.aderente = null;
    } else {
        rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante = aderentePartecipante.value ? true : false;
        rigaCarrelloDati.value.datiCarrello.aderente = JSON.parse(JSON.stringify(aderente.value));
    }
    rigaCarrelloDati.value.datiCarrello.elencoPartecipanti = btoa(JSON.stringify(partecipanti.value));
};

const handleInputDataNascita = (newValue: Date, index: number) => {
    partecipanti.value[index].DataNascita = newValue || null;

    dataAssicuratiModel.value[index] = newValue || null;
    console.log(partecipanti.value[index].DataNascita);
};

const validaFasceEta = () => {
    var fasce = {};
    for (var i = 0; i < partecipanti.value.length; i++) {
        var eta = calcolaEta(partecipanti.value[i].DataNascita || null);
        if (eta < 0) {
            alertText.value = "Specificare una data di nascita valida per il viaggiatore " + (i + 1);
            errori.value.partecipanti[i].dataNascita = true;
            popupAlert.value.show();
            return false;
        }

        var fascia = getFasciaPerEta(eta);

        if (!fascia) {
            alertText.value = "Il viaggiatore " + (i + 1) + " non appartiene a nessuna fascia d'età specificata";
            errori.value.partecipanti[i].dataNascita = true;
            popupAlert.value.show();
            return false;
        }

        if (!fasce[fascia.itemID]) fasce[fascia.itemID] = 0;

        fasce[fascia.itemID]++;
    }

    for (var i = 0; i < parametriPreventivatore.value.fasceEta.length; i++) {
        if ((parametriPreventivatore.value.fasceEta[i].numeroPartecipanti || 0) !== (fasce[parametriPreventivatore.value.fasceEta[i].idFascia] || 0)) {
            alertText.value = "Le date di nascita dei viaggiatori non corrispondono alle fasce d'età specificate";
            popupAlert.value.show();
            return false;
        }
    }

    return true;
};

const calcolaEta = (dataNascita: Date) => {
    if (!dataNascita) return -1;

    var today = new Date();
    var eta = today.getFullYear() - dataNascita.getFullYear();
    var m = today.getMonth() - dataNascita.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dataNascita.getDate())) {
        eta--;
    }

    return eta;
};

const getFasciaPerEta = (eta: number) => {
    if (eta === null || eta < 0) return null;

    for (var i = 0; i < elencoFasceEta.value.length; i++) {
        var f = elencoFasceEta.value[i];
        if ((f.etaMinima === null || f.etaMinima === undefined || eta >= f.etaMinima) && (f.etaMassima === null || f.etaMassima === undefined || eta <= f.etaMassima)) return f;
    }
    return null;
};

const onAggiungiACarrello = () => {
    var err = validaDati();

    if (err.length) {
        alertText.value = err.join("<br>");
        popupAlert.value.show();
        return;
    }

    if (usaFasce.value && !validaFasceEta()) return;

    for (var i = 0; i < partecipanti.value.length; i++) {
        // azzero il codice fiscale dei partecipanti esteri
        if (partecipanti.value[i].Residenza !== "italia") partecipanti.value[i].CodiceFiscale = "";
    }

    if (nascondiContraente.value) {
        rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante = null;
        rigaCarrelloDati.value.datiCarrello.aderente = null;
    } else {
        if (aderente.value.residenza !== "italia") aderente.value.codiceFiscale = "";

        rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante = aderentePartecipante.value ? true : false;
        rigaCarrelloDati.value.datiCarrello.aderente = JSON.parse(JSON.stringify(aderente.value));
    }

    rigaCarrelloDati.value.datiCarrello.elencoPartecipanti = btoa(JSON.stringify(partecipanti.value));
    rigaCarrelloDati.value.datiCarrello.adeguatezza = true;

    next();

    return false;
};

const validateEmail = (email: string): boolean => {
    // prettier-ignore
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const initErrori = () => {
    errori.value = new erroriDatiPolizza();
    errori.value.partecipanti = [];

    for (var i = 0; i < parametriPreventivatore.value.numeroPartecipanti; i++) errori.value.partecipanti.push(new erroriDatiPartecipanti());
};

const validaDati = () => {
    var err = [];

    initErrori();

    if (!nascondiContraente.value) {
        var etaAderente = calcolaEta(aderente.value.dataNascita);
        if (
            !aderente.value.cognome ||
            !aderente.value.nome ||
            !aderente.value.email ||
            !aderente.value.telefono ||
            !aderente.value.indirizzo ||
            !aderente.value.dataNascita ||
            !aderente.value.codiceProvincia ||
            !aderente.value.citta ||
            !aderente.value.cap
        )
            err.push("Compilare tutti i dati del contraente");

        errori.value.aderenteNome = aderente.value.nome ? false : true;
        errori.value.aderenteCognome = aderente.value.cognome ? false : true;
        errori.value.aderenteEmail = aderente.value.email ? false : true;
        errori.value.aderenteTelefono = aderente.value.telefono ? false : true;
        errori.value.aderenteIndirizzo = aderente.value.indirizzo ? false : true;
        errori.value.aderenteDataNascita = aderente.value.dataNascita ? false : true;
        errori.value.aderenteResidenza = aderente.value.residenza ? false : true;
        errori.value.aderenteCap = aderente.value.cap ? false : true;
        errori.value.aderenteCitta = aderente.value.citta ? false : true;
        errori.value.aderenteCodiceProvincia = aderente.value.codiceProvincia ? false : true;

        if (aderente.value.residenza === "italia" && !aderente.value.codiceFiscale) {
            err.push("Compilare il codice fiscale del contraente");
            errori.value.aderenteCodiceFiscale = true;
        } else if (aderente.value.residenza === "italia" && aderente.value.codiceFiscale.length > 16) {
            err.push("Il codice fiscale del contraente ha piu' di 16 caratteri");
            errori.value.aderenteCodiceFiscale = true;
        } else {
            errori.value.aderenteCodiceFiscale = false;
        }

        if (!validateEmail(aderente.value.email)) {
            errori.value.aderenteEmail = true;
            err.push("Email contraente non valida");
        } else if (aderente.value.email != aderente.value.confermaEmail) {
            errori.value.aderenteConfermaEmail = true;
            err.push("La mail del contraente deve coincidere con il campo Conferma Email");
        }

        if (!etaAderente || etaAderente < 18) {
            errori.value.aderenteDataNascita = true;
            err.push("Il contraente deve essere maggiorenne");
        }
    }

    var errDatiPartecipantiSet = false;
    var errCodFiscaleSet = false;

    if (rigaCarrelloDati.value.datiCarrello.numeroPartecipanti !== partecipanti.value.length) err.push("Il numero dei viaggiatori non coincide con quelli impostati");
    else {
        for (var i = 0; i < partecipanti.value.length; i++) {
            var p = partecipanti.value[i];
            errori.value.partecipanti[i].nome = p.Nome ? false : true;
            errori.value.partecipanti[i].cognome = p.Cognome ? false : true;
            errori.value.partecipanti[i].residenza = p.Residenza ? false : true;
            errori.value.partecipanti[i].dataNascita = p.DataNascita ? false : true;

            if (!p.Cognome || !p.Nome || !p.Residenza || !p.DataNascita) {
                if (!errDatiPartecipantiSet) {
                    err.push("Compilare Nome, Cognome, Residenza e Data di Nascita per tutti i viaggiatori");
                    errDatiPartecipantiSet = true;
                }
            }

            if (p.Residenza === "italia") {
                if (!p.CodiceFiscale) {
                    errori.value.partecipanti[i].codiceFiscale = true;
                    if (!errCodFiscaleSet) {
                        err.push("Compilare il codice fiscale dei viaggiatori");
                        errCodFiscaleSet = true;
                    }
                } else if (p.CodiceFiscale.length > 16) {
                    errori.value.partecipanti[i].codiceFiscale = true;
                    err.push("Il codice fiscale del passeggero " + (i + 1) + " ha piu' di 16 caratteri");
                }
            }
        }
    }

    if ( 
        (!widgetConfig.value.nascondiFlagTermini && !checkPolizza.value ) || 
        (!widgetConfig.value.nascondiFlagPrivacy && !checkPrivacy.value ) ||
        (!widgetConfig.value.nascondiFlagSicurezza && !checkSicurezza.value) 
    )
         err.push("Leggere ed accettare i termini e le dichiarazioni");

    return err;
};
</script>

<style scoped>
.webins-link {
    text-decoration: underline;
}
</style>
