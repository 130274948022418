import { Product, ReservationCart } from "@/models";
import { AvailabilityRequest } from "@/services/requests/AvailabilityRequest";
import { CalculateAmountsRequest } from "@/services/requests/CalculateAmountsRequest";
import { CreateReservationRequest } from "@/services/requests/CreateReservationRequest";
import reservationService from "@/services/ReservationService";
import { AvailabilityResponse } from "@/services/responses/AvailabilityResponse";
import { ref, watch, computed, defineComponent } from "vue";
import { useWidget } from "./useWidget";
import { useI18n } from 'vue-i18n';

const loading = ref(false);
const numPaxRequested = ref(1);
const allAvailabilities = ref<AvailabilityResponse[]>([]);
const services = ref<AvailabilityResponse[]>([]);
const existingReservationId = ref<number>();
const reservation = ref<ReservationCart>(new ReservationCart());
const productFilters = ref({
    priceRange: [0,1000],
    options: [{
            code: "BIKE_SIZE",
            description: "Taglia",
            values: [] as string[],
            selected: "",
            haveHelp: true
        },
        {
            code: "BIKE_ADULT_CHILD",
            description: "Formato",
            values: [] as string[],
            selected: "",
            haveHelp: false
        }
    ],
    order: {
        selected: "",
        values: [ "", "Price from lower", "Price from higher" ]
    }
});

export function useReservation() {
    const { fetchData, forcedPickPoint, options,modeCar } = useWidget();
    const { t } = useI18n();

    const getAvailabilities = async (paxAdult: number, paxChild: number, from: Date, to: Date) => {
        const request : AvailabilityRequest = {
            startDate: from,
            endDate: to,
            idScope: options.value.scope,
            areaId: reservation.value.area.id,
            specifiedPickPointOnly: false, // TODO: se pickpoint definito nel load del widget impostare a true
            includeSharedProducts: true,
            productTypes: ["prod"],
            includeRoutes: true,
            availabilityRules: [],
            languageId: t("common.localeCode")
        }
        if(modeCar.value !== true) {
            request.availabilityRules = [
                {
                    minimumNumberOfProducts: paxAdult,
                    optionCode: "BIKE_ADULT_CHILD",
                    optionValue: "adulto"
                },
                {
                    minimumNumberOfProducts: paxChild,
                    optionCode: "BIKE_ADULT_CHILD",
                    optionValue: "bambino"
                }
            ]
        }

        if(forcedPickPoint.value != null) {
            request.pickPointId = forcedPickPoint.value.id;
            request.specifiedPickPointOnly = true;
        }
        const returnedAvailabilities = await fetchData(loading, reservationService.searchAvailabilities(request));
        numPaxRequested.value = parseInt(paxAdult.toString()) + parseInt(paxChild.toString());
        allAvailabilities.value = returnedAvailabilities;
    }

    const getServices = async () => {
        /*const request : AvailabilityRequest = {
            startDate: reservation.value.dateTimeStart,
            endDate: reservation.value.dateTimeEnd,
            idScope: options.value.scope,
            pickPointId: reservation.value.pickPoint.id,
            specifiedPickPointOnly: true, // TODO: se pickpoint definito nel load del widget impostare a true
            includeSharedProducts: false,
            productTypes: ["serv", "acce"], // temp fix - finchè accessori non legati a prodotto, vengono mostrati come servizi
            quantity: 1,
            availabilityRules: [],
            languageId: t("common.localeCode")
        }
        const returnedServices = await fetchData(loading, reservationService.searchAvailabilities(request));
        services.value = returnedServices;*/
    }

    const isProdSelected = (prod: Product) => {
        return reservation.value.selectedProducts.findIndex(p => p.id == prod.id) >= 0;
    };

    const isServSelected = (serv: Product) => {
        const s = reservation.value.selectedServices.find(p => p.id == serv.id);
        return s != null && s._qty > 0;
    };

    const loadReservation = async (id: number, email: string) : Promise<boolean> => {

        const response = await fetchData(loading, reservationService.getReservation(id, email));

        if(response == null) {
            return false;
        }
        const cart = new ReservationCart();
        cart.fromReservationApi(response);
        reservation.value = cart;

        return true;
    }

    const buildReservationRequest = () : CreateReservationRequest => {
        /*const request : CreateReservationRequest = {
            idScope: options.value.scope,
            pickPointId: reservation.value.pickPoint.id,
            dateTimeFrom: reservation.value.dateTimeStart,
            dateTimeTo: reservation.value.dateTimeEnd,
            notes: "",
            products: reservation.value.selectedProducts.map(p => { return {
                id: p.id,
                dateTimeFrom: reservation.value.dateTimeStart,
                dateTimeTo: reservation.value.dateTimeEnd,
                quantity: 1
            }}),
            services: reservation.value.selectedServices.map(s => { return {
                id: s.id,
                dateTimeFrom: reservation.value.dateTimeStart,
                dateTimeTo: reservation.value.dateTimeEnd,
                quantity: s._qty
            }}),
            paxs: reservation.value.paxs,
            paymentMethod: 2, // enum... 2 = PayPal
            calculatedTotalDiscount: reservation.value.totalDiscount,
            calculatedTotalAmount: reservation.value.totalAmount,
            calculatedAppliedCoupon: reservation.value.appliedCoupon,
            customer: {
                firstName: reservation.value.customer.firstName,
                lastName: reservation.value.customer.lastName,
                email: reservation.value.customer.email
            }
        }*/

        return null;
    }

    const createReservation = async () : Promise<boolean> => {
        
        // Se id ordine già esistente, creazione già fatta, si procede con questo...
        if(reservation.value.processingData.idReservation > 0) {
            return true;
        }

        reservation.value.processingData.failure = false;
        const request = buildReservationRequest();
        const response = await fetchData(loading, reservationService.createReservation(request));

        if(response != null) {
            reservation.value.processingData = { failure: false, failureReason: null, ...response };
            if(reservation.value.processingData.idPaymentOrder == null || reservation.value.processingData.idPaymentOrder.length <= 0) {
                reservation.value.processingData.failure = true;
                reservation.value.processingData.failureReason = "Pagamento fallito. Riprovare il pagamento o contattare l'assistenza.";
            }
            return true;
        }

        reservation.value.processingData.failure = true;
        reservation.value.processingData.failureReason = "Errore sconosciuto durante l'elaborazione della richiesta.";
        return false;
    }

    const authorizeReservation = async () : Promise<boolean> => {
        if(reservation.value.processingData.idReservation > 0) {
            const authresult = await fetchData(loading, reservationService.authorizeReservation(reservation.value.processingData.idReservation));

            if(authresult != null && authresult.length > 0) {
                reservation.value.processingData.idPaymentAuth = authresult;
                return true;
            }
            
            reservation.value.processingData.failure = true;
            reservation.value.processingData.failureReason = "Prenotazione inserita ma si è verificato un errore nel processo di pagamento. Riprovare il pagamento o contattare l'assistenza.";
        }
        return false;
    }

    const calculateAmounts = async (coupon?: string) : Promise<{ success: boolean, failMessage: string }> => {
        const retValue = { success: true, failMessage: "" };

        if(coupon == null && reservation.value.appliedCoupon != null) {
            coupon = reservation.value.appliedCoupon;
        }

        const request: CalculateAmountsRequest = {
            totalPrices: reservation.value.totalAmount,
            numberOfPax: reservation.value.paxs.length,
            pickpointId: reservation.value.pickPoint != null ? reservation.value.pickPoint.id : null,
            couponCode: reservation.value.appliedCoupon == null ? coupon : null
        };
        const response = await fetchData(loading, reservationService.calculateAmounts(request));
        if(request.couponCode != null) {
            if(response.couponApplied) {
                reservation.value.totalDiscount = request.totalPrices - response.totalAmount;
                reservation.value.appliedCoupon = coupon;
            }
            else {
                reservation.value.totalDiscount = 0;
                reservation.value.appliedCoupon = null;
                retValue.success = false;
                retValue.failMessage = response.couponApplicationException.message; 
            }
        }
        reservation.value.totalAmount = response.totalAmount;

        return retValue;
    }

    watch(
        () => [reservation.value.selectedProducts, reservation.value.selectedServices, reservation.value.totalDiscount],
        () => {
            if(reservation.value != null) {
                let amount = 0;
                amount += totalAmountProducts.value;
                amount += totalAmountExtras.value;
                reservation.value.totalAmount = amount - reservation.value.totalDiscount;
            }
        },
        { deep: true }
    );

    const totalAmountProducts = computed(() => {
        if(reservation.value.selectedProducts != null && reservation.value.selectedProducts.length > 0) {
            return reservation.value.selectedProducts.reduce((tot, current) => tot + calculatePrice(current), 0);
        }
        return 0;
    });

    const totalAmountExtras = computed(() => {
        if(reservation.value.selectedServices != null && reservation.value.selectedServices.length > 0) {
            return reservation.value.selectedServices.reduce((tot, current) => tot + calculatePrice(current), 0);
        }
        return 0;
    });

    const calculatePrice = (prod: Product): number => {
        let tot = 0;
        
        if(prod.calculatedPrice != null) {
            if(prod._qty == null || prod._qty < 0) {
                prod._qty = 1;
            }
            //tot = prod.calculatedPrice.unitPrice * prod._qty;
            tot = prod.calculatedPrice.price;
        }

        return tot;
    }

    const filteredProducts = computed(() => {
        let filtered: Product[] = reservation.value.availableProducts.filter(
            (prod) => prod != null && prod.calculatedPrice != null
        );

        // ####### FILTERING
        if(reservation.value.availableProducts != null) {
            filtered = filtered.filter((prod) => {
                let match = true;
                // esclusione eventuali prodotti non validi ricevuti o senza opzioni
                if(!prod.productDescription || !prod.options || (prod.options.length == 0 && !modeCar)) return false;

                // filter by selected options
                for (let x = 0; x < productFilters.value.options.length; x++) {
                    const filterOption = productFilters.value.options[x];
                    const option = prod.options.find((o) => o.code.toLowerCase() == filterOption.code.toLowerCase());
                    if(filterOption.selected != null && filterOption.selected.length > 0) {
                        if(option == null)
                            return false;
                        match = match && option.value.toLowerCase() == filterOption.selected.toLowerCase();
                    }
                }
                // filter by price range
                if(!(prod.calculatedPrice.price >= productFilters.value.priceRange[0] && prod.calculatedPrice.price <= productFilters.value.priceRange[1])) {
                    return false;
                }
                return match;
            });
        }

        // ####### SORTING
        return filtered.sort((p1: Product, p2: Product) => { 
            if(productFilters.value.order.selected == "Price from lower") {
                if (p1.calculatedPrice.price < p2.calculatedPrice.price)
                    return -1;
                if (p1.calculatedPrice.price > p2.calculatedPrice.price)
                    return 1;
            }
            else if(productFilters.value.order.selected == "Price from higher") {
                if (p1.calculatedPrice.price > p2.calculatedPrice.price)
                    return -1;
                if (p1.calculatedPrice.price < p2.calculatedPrice.price)
                    return 1;
            }

            // a must be equal to b or no ordering
            return 0; 
        });
    });

    const availabilities = computed(
        () => allAvailabilities.value.filter(av => av.products.some(prod => prod != null && prod.calculatedPrice != null))
    );

    return { 
        loading, reservation, availabilities, services, numPaxRequested, totalAmountProducts, totalAmountExtras,
        getAvailabilities, getServices, loadReservation, createReservation, calculateAmounts, authorizeReservation,
        existingReservationId, isProdSelected, isServSelected, productFilters, filteredProducts, buildReservationRequest
    }
}