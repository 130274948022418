<template>
    <div class="webins-widget-step-cols">
        <div class="availabilities-panel">
            <!-- <h1>Prodotti Selezionati -->
            <!-- <button class="btn btn-secondary" @click="prev">Indietro</button> -->
            <!-- </h1> -->
            <Result />
        </div>
        <div>
            <SummaryRisultati />
        </div>
    </div>
    <Loading :isloading="loading" />
</template>

<script lang="ts" setup>
import {} from "vue";
import { useI18n } from "vue-i18n";
import Loading from "../../loading.vue";
import SummaryRisultati from "../../summary-risultati.vue";
import Result from "./result.vue";
import { useNavigation, usePolizze } from "@/composables";

const { t } = useI18n();
const { prev } = useNavigation();
const { loading } = usePolizze();
</script>
