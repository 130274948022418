<template>
    <div class="d-flex flex-column" style="border: 1px solid #afafaf; border-radius: 15px; max-width: 280px; min-width: 280px">
        <div class="webins-row m-0">
            <div class="webins-col-12 m-0" style="height: 75px">
                <div class="mt-3 fw-bold h3">{{ prodotto.pacchetto }}</div>
            </div>
        </div>
        <!-- <div class="webins-row m-0 mt-1 mb-1">
            <div class="webins-col-12" cols="12">
                <div class="w-100" style="cursor: pointer" @click="showGaranzie = !showGaranzie">
                    <span style="text-decoration: underline; color: #3276c8">Vedi Garanzie</span>
                    <svg viewBox="0 0 16 16" width="1em" height="1em" aria-label="chevron up" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-chevron-up ml-3 b-icon bi" color="#3276c8" v-if="showGaranzie" v-bind:svg-inline="''" v-bind:class="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M7.469 2.969a.75.75 0 011.062 0l9 9a.75.75 0 01-1.062 1.062L8 4.561l-8.469 8.47a.75.75 0 01-1.062-1.062l9-9z"/></svg>
                    <svg viewBox="0 0 16 16" width="1em" height="1em" aria-label="chevron down" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-chevron-down ml-3 b-icon bi" color="#3276c8" v-else="" v-bind:svg-inline="''" v-bind:class="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M-1.531 2.969a.75.75 0 011.062 0L8 11.439l8.469-8.47a.75.75 0 011.062 1.062l-9 9a.75.75 0 01-1.062 0l-9-9a.75.75 0 010-1.062z"/></svg>
                </div>
            </div>
        </div> -->
        <div class="webins-row m-0">
            <div class="webins-col-12" cols="12">
                <div v-for="(g, index) in parametriValid" :key="index">
                    <div
                        class="d-flex justify-content-between align-items-center py-2 mb-1"
                        style="background-color: #efefef; border-radius: 10px; padding-bottom: 6px"
                        @click="showDetail[index] = !showDetail[index]"
                    >
                        <div class="d-flex align-items-center">
                            <CheckCategoriaParametri :parametri="g" :prodotto="prodotto" class="mt-1"></CheckCategoriaParametri>
                            <div>
                                <strong>{{ g.categoria }}</strong>
                            </div>
                        </div>
                        <div class="mr-3">
                            <svg viewBox="0 0 16 16" width="1em" height="1em" aria-label="chevron up" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-chevron-up ml-3 b-icon bi" color="#3276c8" v-if="showDetail[index]" v-bind:svg-inline="''" v-bind:class="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M7.469 2.969a.75.75 0 011.062 0l9 9a.75.75 0 01-1.062 1.062L8 4.561l-8.469 8.47a.75.75 0 01-1.062-1.062l9-9z"/></svg>
                            <svg viewBox="0 0 16 16" width="1em" height="1em" aria-label="chevron down" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-chevron-down ml-3 b-icon bi" color="#3276c8" v-else="" v-bind:svg-inline="''" v-bind:class="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M-1.531 2.969a.75.75 0 011.062 0L8 11.439l8.469-8.47a.75.75 0 011.062 1.062l-9 9a.75.75 0 01-1.062 0l-9-9a.75.75 0 010-1.062z"/></svg>
                        </div>
                    </div>
                    <Collapsible class="p-1" :visible="showDetail[index]">
                        <div v-for="(o, index) in g.parametri" :key="index" class="d-flex justify-content-between py-2">
                            <div>{{ o.descrizione }}</div>
                            <CheckParametri :parametro="o" :prodotto="prodotto"></CheckParametri>
                        </div>
                    </Collapsible>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column" style="height: 100%; justify-content: flex-end">
            <div class="webins-row mt-2">
                <div class="text-center webins-col-12" cols="12">
                    <template v-if="prodotto.totali && prodotto.totali.scontoCoupon">
                        <div class="d-flex" style="justify-content: center;">
                            <h4 style="color: #e16229;position: absolute;margin-left: 155px;margin-top: 0px;text-decoration: line-through;" class="font-weight-bold" >{{ numberToCurrency( (prodotto.totali.totaleViaggio + prodotto.totali.scontoCoupon ) as any) }}</h4>
                            <h2 style="color: #31ce56" class="font-weight-bold">{{ numberToCurrency(prodotto.totali.totaleViaggio as any) }}</h2>
                    
                        </div>
                        
                    </template>
                    <template v-else-if="prodotto.totali && prodotto.totali.totaleViaggio">
                        <h2 style="color: #e16229" class="font-weight-bold">{{ numberToCurrency(prodotto.totali.totaleViaggio as any) }}</h2>
                    </template>
                    <template v-else>
                        <h2 style="color: #e16229" class="font-weight-bold">..... €</h2>
                    </template>
                    <!-- <h2 v-if="prodotto.totali && prodotto.totali.totaleViaggio" style="color: #e16229" class="font-weight-bold">{{ numberToCurrency(prodotto.totali.totaleViaggio) }}</h2> -->
                    
                </div>
            </div>
            <div class="webins-row my-3">
                <div cols="12" class="webins-col-12 text-center">
                    <button style="width: 90%; border-radius: 10px; margin-bottom: 10px" class="btn text-uppercase font-weight-bold" @click="onSelezionaProdotto">
                        Seleziona
                    </button>
                </div>
                <div cols="12" class="webins-col-12 text-center mt-2">
                    <button style="width: 90%; border-radius: 10px" class="btn webins-btn-secondary text-uppercase font-weight-bold outline" @click="getTerminiECondizioni(prodotto)">
                        Termini e Condizioni
                    </button>
                </div>
            </div>
        </div>

        <Popup ref="popupAlert">
            <div style="font-weight:bold" v-html="alertText"></div>
        </Popup>
        <Popup ref="popupSuggerimento">
            <div class="bike-rent-product-card">
                <div style="margin: 10px; font-size: 20px; text-align: center;" v-if="!textSuggerimento">Con soli {{ numberToCurrency(differenzaPolizza as any ) }} in piu' puoi scegliere la polizza 
                    <strong>{{ polizzaSuggerita.pacchetto }}</strong>
                </div>
                <div style="margin: 10px; font-size: 20px; text-align: center;" v-else v-html="textSuggerimento">
                </div>  


                <div class="d-flex" style="margin-top: 20px">
                    <button style="width: 50%; border-radius: 10px; margin-bottom: 10px" class="btn info-prodotto-carrello btn-app-primary text-uppercase" @click="stepSuccessivo(prodotto)">
                        Continuo con {{ prodotto.pacchetto }}
                    </button>
                    
                    <button
                        style="width: 50%; border-radius: 10px; margin-bottom: 10px"
                        class="btn info-prodotto-carrello text-uppercase"
                        @click="onClickOpzione2Suggerimento"
                    >
                    {{  textOpzione2Suggerimento || 'Mostrami di nuovo le opzioni' }}
                        
                    </button>

                </div>


                <!-- <div class="my-1 text-center">
                    <button style="width: 90%; border-radius: 10px; margin-bottom: 10px" class="btn info-prodotto-carrello btn-app-primary text-uppercase" @click="stepSuccessivo(prodotto)">
                        Continua con la polizza {{ prodotto.pacchetto }}
                    </button>
                    
                </div>

                <div class="my-1 text-center">
                   
                    <button
                        style="width: 90%; border-radius: 10px; margin-bottom: 10px"
                        class="btn info-prodotto-carrello text-uppercase"
                        @click="popupSuggerimento.close"
                    >
                        Annulla
                    </button>
                 
                </div> -->
            </div>
        </Popup>
    </div>
</template>

<style scoped>
.commissione {
    border-radius: 20px;
    font-weight: bold;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    color: white;
    font-size: 1.5em;
}
</style>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { usePolizze, useNavigation, useWidget } from "@/composables";
import webinsService from "@/services/WebinsService";
import Collapsible from "@/components/collapsible.vue";
import CheckCategoriaParametri from "@/components/checkCategoriaParametri.vue";
import CheckParametri from "@/components/checkParametri.vue";
import { AssociaDistributoriPacchettiExtended, gruppoSchedaProdottoParametri } from "@/models/AssociaDistributoriPacchetti";
import Popup from "@/components/popup.vue";
import { RigaCarrello, RigaCarrelloDati } from "@/models/RigaCarrello";
import { datiAderente } from "@/models/DatiAderente";
import { PartecipanteItem } from "@/models/Partecipante";


const { rigaCarrelloDati } = usePolizze();
const { widgetConfig, datiIniziali, options} = useWidget();


const popupSuggerimento = ref(null);

const popupAlert = ref(null);
const alertText = ref("");

const polizzaSuggerita = ref<AssociaDistributoriPacchettiExtended>();
const differenzaPolizza = ref(0);

const showGaranzie = ref<boolean>(false);
const showDetail = ref<boolean[]>([]);

const prodottoSuggerito = ref<AssociaDistributoriPacchettiExtended>();
const usaImportoViaggio = ref<boolean>(false);

const props =
    defineProps<{
        prodotto?: AssociaDistributoriPacchettiExtended;
        parametri?: gruppoSchedaProdottoParametri[];
    }>();

onMounted(() => {
    usaImportoViaggio.value = widgetConfig.value && widgetConfig.value.usaImportoViaggio ? true : false;
    for (let index = 0; index < props.parametri.length; index++) {
        showDetail.value[index] = false;
    }
});

const { next } = useNavigation();
const { loading, numberToPercentage, numberToCurrency, numberToDecimal, numberToInteger, getSuggerimentiPolizza, getTotaliPolizza, parametriPreventivatore, getTerminiECondizioni, setRigaCarrello } =
    usePolizze();

const isAppMobile = ref(false);

const onSelezionaProdotto = () => {
    //var suggerimenti = await getSuggerimentiPolizza(parametriPreventivatore.value, props.prodotto.pacchettoID);

    var suggerimento = getSuggerimento(props.prodotto);
    if (!suggerimento) {
        // vai a step successivo
        stepSuccessivo(props.prodotto);
    } else {
        polizzaSuggerita.value = suggerimento;
        popupSuggerimento.value.show();
    }
};

const getSuggerimento = (prodotto: AssociaDistributoriPacchettiExtended) => {

    if(!prodotto.prodottiCorrelati || !prodotto.prodottiCorrelati.length)
        return null;

    for(var i = 0; i < prodotto.prodottiCorrelati.length; i++){
        if(prodotto.prodottiCorrelati[i].totali && prodotto.prodottiCorrelati[i].totali.totaleViaggio > 0){
            // add controllo
            differenzaPolizza.value = prodotto.prodottiCorrelati[i].totali.totaleViaggio - prodotto.totali.totaleViaggio;
            return prodotto.prodottiCorrelati[i]
        }
    }
    return null;
}


const textSuggerimento = computed(() => {
    /*var text = "Per soli {valore} di differenza è possibile scegliere la polizza <strong>{nomeProdotto}</strong>" +
        "<br><a href='#'>Vai alla pagina della polizza {nomeProdotto}</a>";
    text = text.split("{valore}").join(numberToCurrency(differenzaPolizza.value as any));
    text = text.split("{nomeProdotto}").join( polizzaSuggerita.value.pacchetto);
    */
    return "";
});

const textOpzione2Suggerimento = computed(() => {
    if(options.value.linkProdottiSuggeriti && options.value.linkProdottiSuggeriti[polizzaSuggerita.value.pacchettoID]){
        return "Mostrami " + polizzaSuggerita.value.pacchetto
    }
    return "";
});


const onClickOpzione2Suggerimento = () => {
    if(options.value.linkProdottiSuggeriti && options.value.linkProdottiSuggeriti[polizzaSuggerita.value.pacchettoID]){
        const url = options.value.linkProdottiSuggeriti[polizzaSuggerita.value.pacchettoID];
        const link = document.createElement("a");
        link.href = url;
        //link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    else
        popupSuggerimento.value.close();
}

const parametriValid = computed(() => {
    // estrapola solo le categorie di parametri che hanno almeno 1 parametro settato
    var par = [];

    props.parametri.forEach((parametro) => {
        var allFalse = true;

        props.prodotto.parametri.forEach((parametroProdotto) => {
            if (parametro.categoria == parametroProdotto.categoria && parametroProdotto.valore && (parametroProdotto.tipo !== 1 || parametroProdotto.valore !== "0")) {
                allFalse = false;
            }
        });
        if (!allFalse) par.push(parametro);
    });

    return par;
});

const onClickDettaglio = (prod) => {
    return false;
};

const stepSuccessivo = async (prod: AssociaDistributoriPacchettiExtended) => {
    const r = new RigaCarrello();

    r.pacchettoID = prod.pacchettoID;
    r.inizioViaggio = parametriPreventivatore.value.inizioViaggio;
    r.fineViaggio = parametriPreventivatore.value.fineViaggio;
    r.numeroPartecipanti = parametriPreventivatore.value.numeroPartecipanti;
    r.statoID = parametriPreventivatore.value.destinazioneStato;
    r.fasceEta = parametriPreventivatore.value.fasceEta || [];
    r.nazionalitaID = 1;
    r.codicePromo = parametriPreventivatore.value.codiceCoupon || null;

    r.importoViaggio = usaImportoViaggio.value ? parametriPreventivatore.value.importoViaggio || 0 : 0;

    if(rigaCarrelloDati.value && rigaCarrelloDati.value.datiCarrello && rigaCarrelloDati.value.datiCarrello.aderente)
        r.aderente = JSON.parse(JSON.stringify(rigaCarrelloDati.value.datiCarrello.aderente));

    if(rigaCarrelloDati.value && rigaCarrelloDati.value.datiCarrello && rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante)
        r.aderenteIsPartecipante = rigaCarrelloDati.value.datiCarrello.aderenteIsPartecipante;

    if(rigaCarrelloDati.value && rigaCarrelloDati.value.datiCarrello && rigaCarrelloDati.value.datiCarrello.elencoPartecipanti)
        r.elencoPartecipanti = rigaCarrelloDati.value.datiCarrello.elencoPartecipanti;
    //r.importoViaggio = this.importoViaggioAbilitato() ? par.importoViaggio || 0 : 0;

    var tot = await getTotaliPolizza(r);

    if (!tot || (tot.erroriTotaleCarrello && tot.erroriTotaleCarrello.length)) {
        var msg = [];
        /*if (tot.erroriTotaleCarrello && tot.erroriTotaleCarrello.length) {
            for (var i = 0; i < tot.erroriTotaleCarrello.length; i++) {
                var codice = tot.erroriTotaleCarrello[i].codice;
                var errMsg = tot.erroriTotaleCarrello[i].descrizione;

                if (codice) {
                    errMsg = t(codice).toString();
                    if (tot.data.erroriTotaleCarrello[i].data && (tot.data.erroriTotaleCarrello[i].data as any).pacchetto) {
                        errMsg += ": " + (tot.data.erroriTotaleCarrello[i].data as any).pacchetto;
                    }
                }

                self.$bvToast.toast(errMsg, {
                    variant: "danger",
                    title: self.$i18n.t("msg.erroreGenerico").toString(),
                    solid: true
                });

                // self.onAddComplemetare();
            }

            return;
        }*/

        alertText.value = "Dati non validi. Contattare Webins";
        popupAlert.value.show();
        return;
    }

    var rDati: RigaCarrelloDati = { datiCarrello: r, datiProdotto: prod };

    if(widgetConfig.value && widgetConfig.value.abilitaAggiuntaCarrello){
        // add dati partecipanti ed eventualmente contraente
        addDatiPartecipantiEContraente(rDati);
    }

    setRigaCarrello(rDati);

    next();
    return false;
};


const addDatiPartecipantiEContraente = (rigaDati : RigaCarrelloDati) => {
    
    var partecipanti : PartecipanteItem[] = [];

    const mostraNazionalita = rigaDati.datiProdotto.mostraNazionalita ? true : false;

    if(!widgetConfig.value.nascondiContraente){
        var dInizialiAderente = datiIniziali.value && datiIniziali.value.datiContraente ? datiIniziali.value.datiContraente : null;

        if (dInizialiAderente) {
            const aderente : datiAderente = {
                cognome: dInizialiAderente.cognome || "",
                nome: dInizialiAderente.nome || "",
                email: dInizialiAderente.email || "",
                confermaEmail: "",
                indirizzo: dInizialiAderente.indirizzo || "",
                telefono: dInizialiAderente.telefono || "",
                residenza: dInizialiAderente.residenza || "",
                cap: dInizialiAderente.cap || "",
                comune: dInizialiAderente.comune || "",
                citta: dInizialiAderente.citta || "",
                codiceProvincia: dInizialiAderente.codiceProvincia || "",
                codiceFiscale: dInizialiAderente.codiceFiscale || "",
                dataNascita: dInizialiAderente.dataNascita ? new Date(dInizialiAderente.dataNascita) : null,
                consensoMarketing: false
            };

            if(!mostraNazionalita)
                aderente.residenza = "italia";

            rigaDati.datiCarrello.aderente = aderente;
        }
    }

    var pDatiIniziali = datiIniziali.value.assicurati || [];
    for (var i = 0; i < parametriPreventivatore.value.numeroPartecipanti; i++) {
        partecipanti.push({
            ItemID: i + 1,
            Nome: pDatiIniziali[i] ? pDatiIniziali[i].nome : "",
            Cognome:  pDatiIniziali[i] ? pDatiIniziali[i].cognome : "",
            DataNascita:  pDatiIniziali[i] && pDatiIniziali[i].dataNascita ? new Date(pDatiIniziali[i].dataNascita): null,
            CodiceFiscale:  pDatiIniziali[i] ? pDatiIniziali[i].codiceFiscale : "",
            Residenza:  pDatiIniziali[i] ? pDatiIniziali[i].residenza : "italia",
            FasciaEta: null
        });

        if(!mostraNazionalita)
            partecipanti[i].Residenza = "italia";

    }

    rigaDati.datiCarrello.elencoPartecipanti = btoa(JSON.stringify(partecipanti));
    rigaDati.datiCarrello.adeguatezza = true;
};

</script>
