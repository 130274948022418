import { GetReservationResponse } from "@/services/responses/GetReservationResponse";
import { Area, Pax, Product, PickPoint } from "./";

export class ReservationCart {
    constructor() {
        this.reset();
    }

    fromReservationApi(reservationResponse: GetReservationResponse) {
        this.reset();

        this.dateTimeStart = reservationResponse.reservation.dateFrom;
        this.dateTimeEnd = reservationResponse.reservation.dateTo;
        this.dateTimeTaken =  reservationResponse.reservation.dateTaken;
        this.totalDiscount = reservationResponse.reservation.discountAmount;
        this.totalAmount = reservationResponse.reservation.totalAmount;
        if(reservationResponse.reservation.coupon != null) {
            this.appliedCoupon = reservationResponse.reservation.coupon.code;
        }
        this.area = reservationResponse.area;
        this.pickPoint = reservationResponse.pickPoint;
        this.customer = reservationResponse.customer;
        this.paxs = reservationResponse.reservation.paxs;

        this.selectedProducts = reservationResponse.reservation.productReservationDMs
            .filter(p => p.productType == "prod")
            .map(p => {
                return {
                    id: p.id,
                    productDescription: p.productDescription,
                    productType: p.productType,
                    stockItem: p.stockItem,
                    parentProductId: p.product,
                    isActive: true,
                    notes: "",
                    pickPoint: reservationResponse.pickPoint,
                    prices: [],
                    hasPrices: true,
                    mainMedia: null,
                    media: [],
                    calculatedPrice: {
                        quantity: p.quantity,
                        price: p.price,
                        unitPrice: p.price / p.quantity,
                        currency: p.currency,
                        uoM: p.uoM,
                        appliedPriceListId: p.appliedPriceListId,
                        uoMDescription: p.uoMDescription
                    },
                    options: [],
                    _qty: p.quantity
                }
            });

        this.selectedServices = reservationResponse.reservation.productReservationDMs
            .filter(p => p.productType == "serv" || p.productType == "acce")
            .map(p => {
                return {
                    id: p.id,
                    productDescription: p.productDescription,
                    productType: p.productType,
                    stockItem: p.stockItem,
                    parentProductId: p.product,
                    isActive: true,
                    notes: "",
                    pickPoint: reservationResponse.pickPoint,
                    prices: [],
                    hasPrices: true,
                    mainMedia: null,
                    media: [],
                    calculatedPrice: {
                        quantity: p.quantity,
                        price: p.price,
                        unitPrice: p.price / p.quantity,
                        currency: p.currency,
                        uoM: p.uoM,
                        appliedPriceListId: p.appliedPriceListId,
                        uoMDescription: p.uoMDescription
                    },
                    options: [],
                    _qty: p.quantity
                }
            });
        
        this.processingData = {
            failure: false,
            failureReason: "",
            amountPaid: reservationResponse.reservation.amountPaid,
            idReservation: reservationResponse.reservation.id,
            idOrder: reservationResponse.reservation.orderId,
            idPaymentOrder: reservationResponse.idPaymentOrder,
            idPaymentAuth: reservationResponse.idPaymentAuth
        }

        if(this.processingData.idPaymentOrder == null || this.processingData.idPaymentOrder.length == 0 || this.processingData.idPaymentAuth == null || this.processingData.idPaymentAuth.length == 0) {
            this.processingData.failure = true;
            this.processingData.failureReason = "Prenotazione in attesa di completamento del pagamento.";
        }
    }

    reset = () => {
        this.totalDiscount = 0;
        this.totalAmount = 0;
        this.appliedCoupon = null;
        this.pickPoint = null;
        this.availableProducts = [];
        this.selectedProducts = [];
        this.availableServices = [];
        this.selectedServices = [];
        this.paxs = [];
        this.processingData = {
            failure: false,
            failureReason: null,
            idReservation: 0,
            idOrder: 0,
            idPaymentOrder: null,
            amountPaid: 0
        };
        this.customer = {
            firstName: "",
            lastName: "",
            email: "",
            isCompany: false,
            taxCode: "",
            companyName: ""
        };
    }

    // step 0
    area: Area;
    // step 1
    dateTimeStart: Date;
    dateTimeEnd: Date;
    dateTimeTaken: Date;
    pickPoint?: PickPoint;
    availableProducts?: Product[];
    // step 2
    selectedProducts: Product[];
    // step 3
    availableServices?: Product[];
    selectedServices: Product[];
    // step 4
    paxs: Pax[];

    totalDiscount: number;
    totalAmount: number;
    appliedCoupon?: string;

    customer: {
        firstName: string;
        lastName: string;
        email: string; 
        isCompany: boolean;
        taxCode: string;
        companyName: string;
    }

    processingData: {
        failure: boolean,
        failureReason: string,
        idReservation: number,
        idOrder: number,
        idPaymentOrder: string,
        idPaymentAuth?:string,
        amountPaid: number
    }
}