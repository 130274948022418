<template>
    <!-- <div class="webins-widget-step-0" :class="usaFasce ? '' : 'webins-no-fasce'" :style="{ 'background-image': `url(https://webins.it/wp-content/uploads/2023/07/1-nuovahomeRID.jpg)`, 'background-position': '0px 40%' }"> -->
    <div class="webins-widget-step-0" :style="{ 'background-image': `url(https://webins.it/wp-content/uploads/2023/07/1-nuovahomeRID.jpg)`, 'background-position': '0px 40%' }">
        <!-- <div class="tagline">
            <h1>
                <p>
                    <span>{{ t("step0.tagline1_2") }}</span>
                </p>
                <p>
                    <span>{{ t("step0.tagline2") }}</span>
                </p>
                <p>
                    <span>{{ t("step0.tagline3") }}</span>
                </p>
            </h1>
        </div> -->
        <div class="d-flex justify-content-center" style="align-items: flex-end;" v-if="!widgetConfig || !widgetConfig.abilitaAggiuntaCarrello">
            <FormPreventivatore />
        </div>
    </div>
    <Loading :isloading="loading" />
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { usePolizze, useWidget } from "@/composables";
import Loading from "../../loading.vue";
import FormPreventivatore from "./formPreventivatore.vue";

const { t } = useI18n();
const { widgetConfig } = useWidget();
const { loading } = usePolizze();
const usaFasce = ref<boolean>(false);


onMounted(() => {
    //dataPrenotazione.value = dayjs(today.value);
    usaFasce.value = widgetConfig.value && widgetConfig.value.usaFasceEta ? true : false;

});

</script>
