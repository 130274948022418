<template>
  <div class="webins-step-conferma">

    <div class="box-review center" v-if="state == 'addToCart'">
      <h3>{{ msgAddToCart || 'La polizza è stata aggiunta al carrello' }}</h3>
    </div>
    <div class="box-review center" v-else-if="state == 'search'">
      <div v-if="chiediEmailConferma">
        <h3>Inserire la mail associata all'acquisto per procedere alla visualizzazione</h3>
        <input class="email-validation" type="email" v-model="existingEmail" placeholder="email" @keyup.enter="getAdesioneSearch" />
        <p v-if="invalidMail.length > 0" style="color: red">
          {{ invalidMail }}
        </p>
        <button class="btn" @click="getAdesioneSearch">Mostra acquisto</button>
      </div>
      <div v-else>
        <p v-if="invalidMail.length > 0" style="color: red">
          {{ invalidMail }}
        </p>
        <p v-else>Recupero pratica in corso</p>
        
      </div>
      
    </div>
    <div v-else>
      <div class="box-review" v-if="state == 'confirm'">
        <h1 class="center" v-if="widgetConfig.isWondersafe">
          Grazie per aver assicurato il tuo viaggio con Wondersafe
        </h1>
        <h1 v-else class="center">
          Grazie per aver assicurato il tuo viaggio
        </h1>
        
        <h2 style="font-size: 18px; text-align: center;">Abbiamo inviato un’email con il dettaglio della polizza a <br>{{ adesioneCreata.datiAderente.email}}</h2>
        
        </div>

      <div class="box-review d-flex justify-content-center" >
        <button class="btn"  style="font-size: 18px; text-align: center;" @click="scaricaCertificato">Scarica il certificato assicurativo</button>
      </div>

      <div class="box-review">
        <!-- <h3 class="with-subtitle">Riepilogo del tuo acquisto</h3>
         Numero polizza: {{ adesioneCreata.datiAdesione.numeroCompleto }} -->
        <div class="reservation-main-data">
          <!-- <div>
            <small>Polizza</small><br>
            <strong>{{ rigaCarrelloDati.datiProdotto.pacchetto  }}</strong>
          </div> -->
          <div>
            <small>Inizio Viaggio</small><br>
            <strong>{{ inizioViaggio }}</strong>
          </div>
          <div>
            <small>Fine Viaggio</small><br>
            <strong>{{ fineViaggio }}</strong>
          </div>
          <div>
            <small>Destinazione</small><br>
            <strong>{{ destinazioneNome }}</strong>
          </div>
          <div>
            <small>Viaggiatori</small><br>
            <!-- <strong>{{ rigaCarrelloDati.datiCarrello.numeroPartecipanti }}</strong> -->
            <strong>{{ adesioneCreata.datiAdesione.numeroPartecipanti }}</strong>
          </div>
        </div>
      </div>

      <div class="box-review reservation-items">
        <ReservationItems :enableDetailButton="false" :showMainMedia="false" :enableDel="false" />
      </div>

       <div class="box-review">
        <h3>Viaggiatori</h3>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Data di Nascita</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pax in partecipanti" :key="pax.itemID">
              <td>{{  pax.nome + ' ' + pax.cognome }}</td>
              <td>{{  pax.dataNascita || '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div> 
    </div>
    <div class="box-review box-failure" v-if="transazione && (transazione.adesioniErrori && transazione.adesioniErrori.length )">
        <p  v-for="(err, index) in transazione.adesioniErrori" :key="index" >
            <strong>{{ err.descrizione }}</strong>
        </p>
    </div>

    <!-- <div class="box-review box-failure" v-if="reservation.processingData.failure == true">
      <p><strong>{{reservation.processingData.failureReason}}</strong></p>
      <hr>
      <h3>Riprovare il pagamento</h3>
      <PayPalButton />, toValue, toValue
    </div> -->

  </div>
  <Loading :isloading="loading" />  
</template>
 
<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";
import { useI18n } from 'vue-i18n';
import dayjs from "dayjs";
import Loading from "../../loading.vue";
import ReservationItems from "../pagamento/reservationItems.vue";
import PayPalButton from "../pagamento/paypalButton.vue";
import { useReservation, usePolizze, useWidget } from "@/composables";
import { Pax } from "@/models";


const { t } = useI18n();
const { reservation, loadReservation } = useReservation();


const { loading, parametriPreventivatore, totali, rigaCarrelloDati, transazione, adesioneCreata, numberToCurrency, 
  downloadCertificato, guidAdesioneSearch, caricaAdesione, msgAddToCart } = usePolizze();
const { destinazioniPreventivatore, widgetConfig, isPaymentInternal } = useWidget();

const existingEmail = ref("");
const invalidMail = ref("");
const nascondiContraente = ref<boolean>(false);

onMounted(() => {
  nascondiContraente.value = widgetConfig.value && widgetConfig.value.nascondiContraente ? true : false;
  if(guidAdesioneSearch.value && !chiediEmailConferma.value ){
    getAdesioneSearch();
  }
});



const partecipanti = computed( () => {
  adesioneCreata.value.datiAdesione.partecipantiElencoLista
  //var p = rigaCarrelloDati.value.datiCarrello.elencoPartecipanti ? JSON.parse( atob( rigaCarrelloDati.value.datiCarrello.elencoPartecipanti)) : [];
  var p = adesioneCreata.value.datiAdesione.partecipantiElencoLista;
  for(var i = 0; i < p.length; i++)
    //p[i].dataNascita && (p[i].dataNascita = dayjs(p[i].dataNascita ).format("DD/MM/YYYY"));
    p[i].dataNascita && ( (p[i].dataNascita as unknown ) = dayjs(p[i].dataNascita ).format("DD/MM/YYYY"));
  return p;
});


/*
const getReservation = async () => {
  invalidMail.value = "";
  const loaded = await loadReservation(existingReservationId.value, existingEmail.value);
  if(loaded == false) {
    invalidMail.value = "Nessuna prenotazione disponibile associata alla mail specificata..";
  }
}*/

const getAdesioneSearch = async () => {
  invalidMail.value = "";
  const adesioneCaricata = await caricaAdesione(guidAdesioneSearch.value, existingEmail.value);
  if(!adesioneCaricata) {
    invalidMail.value = chiediEmailConferma.value ? "Nessuna polizza disponibile associata alla mail specificata.." : "Polizza non trovata";
  }
}



const scaricaCertificato = async() => {
  await downloadCertificato(adesioneCreata.value.guidAdesione);
}

const state = computed(() => {
  if(guidAdesioneSearch.value ) {
    if(!adesioneCreata.value) {
      return "search";
    }
    else {
      return "review";
    }
  }
  else if( !isPaymentInternal.value) {
    return "addToCart";
  }
  else {
    return "confirm";
  }
});

const chiediEmailConferma = computed(() => {
    return widgetConfig.value && widgetConfig.value.chiediEmailSuRecuperoAdesione ? true : false;
});

const partecipantiPerFascia = computed(() => {
    if( !parametriPreventivatore.value.fasceEta || !parametriPreventivatore.value.fasceEta.length )
        return [];

    var p = []
    for( var i = 0; i < parametriPreventivatore.value.fasceEta.length; i++){
        if(parametriPreventivatore.value.fasceEta[i].numeroPartecipanti )
            p.push(parametriPreventivatore.value.fasceEta[i]);
    }
    return p;
});

const inizioViaggio = computed(() => {
    if (adesioneCreata.value.datiAdesione.inizioViaggio != null) {
        return dayjs(adesioneCreata.value.datiAdesione.inizioViaggio).format(t("common.dateUIFormat") || "DD/MM/YYYY");
    }
    return "?";
});

const fineViaggio = computed(() => {
    if (adesioneCreata.value.datiAdesione.fineViaggio != null) {
        return dayjs(adesioneCreata.value.datiAdesione.fineViaggio).format(t("common.dateUIFormat") || "DD/MM/YYYY");
    }
    return "?";
});

const destinazioneNome = computed(() => {
    if (adesioneCreata.value.datiAdesione.destinazioneID != null) {
        var d = destinazioniPreventivatore.value.find((a) => a.value === adesioneCreata.value.datiAdesione.destinazioneID);
        return d && d.text ? d.text : "-";
    }
    return "-";
});



const taken = computed(() => {
  /*if(reservation.value.dateTimeTaken != null) {        
    return dayjs(reservation.value.dateTimeTaken).format(t("common.dateTimeUIFormat"));
  }
  return dayjs().format(t("common.dateTimeUIFormat"))*/
  return "";
});

const getPaxName = (pax: Pax) => {
  let name = "";

  if(pax.firstName != null && pax.firstName.length > 0) {
    name += pax.firstName;
  }

  if(pax.lastName != null && pax.lastName.length > 0) {
    if(name.length > 0) {
      name += " ";
    }
    name += pax.firstName;
  }

  if(name.length == 0) {
    name = "not specified";
  }

  return name;
}


</script>