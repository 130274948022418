export class ParametriPreventivatoreItem {
	dataPrenotazione: Date | null;
	inizioViaggio: Date | null;
	fineViaggio: Date | null;
	destinazioneStato: number | null;
	eta: number | null;
	numeroPartecipanti: number | null;
	fasceEta: ParametriPreventivatoreFasceEta[] | null;
	codiceCoupon: string | null;
	importoViaggio: number | null;
	//nazionalita: number | null;
	prodottiSelezionati: number[] | null;

	constructor(){
		this.dataPrenotazione = null;
		this.inizioViaggio = null;
		this.fineViaggio = null;
		this.destinazioneStato = null;
		this.eta = null;
		this.numeroPartecipanti = null;
		this.fasceEta = null;
		this.codiceCoupon = null;
		this.importoViaggio = null;

		//this.nazionalita = null;
		//this.importoViaggio = null;
		this.prodottiSelezionati = [];
	}
}

export class ParametriPreventivatoreFasceEta {
	idFascia: number| null;
	descrizione: string | null;
	numeroPartecipanti: number | null;
	
	constructor(){
		this.idFascia = null;
		this.descrizione = null;
		this.numeroPartecipanti = null;
	}
}


