<template>
  <div class="webins-widget-navigation" :class="navigationContainerClass" v-if="isLargeScreen">
    <div v-for="step in uiSteps" :key="step.step" class="webins-widget-navigation-item" 
        @click="goToStep(step.step)" style="cursor: pointer"
        :class="{ 'completed': step.step < currentStep, 'selected' : currentStep==step.step}">
        {{ step.desc }}
    </div>
  </div>
  <div v-else class="webins-widget-navigation-mob">
    <div class="completion-header">
      <div style="float:left">{{steps[currentStep].desc}}</div>
      <div class="text-theme-color" style="text-align: right">
        {{ t("common.riepilogoDati") }}
        <span>€ {{ totali && totali.totaleViaggio ? totali.totaleViaggio : '0' }}</span>
        <a class="mob-toggle-btn" style="cursor: pointer" @click="mobileMenuOpened = !mobileMenuOpened">
          <svg data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-if="!mobileMenuOpened" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M100 150.5a6.49 6.49 0 01-4.6-1.9l-70-70a6.51 6.51 0 019.2-9.2l65.4 65.41 65.4-65.41a6.51 6.51 0 019.2 9.2l-70 70a6.49 6.49 0 01-4.6 1.9z" data-name="Tracciato 18"/></svg>
          <svg data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M97.8 58.34a6.47 6.47 0 00-4.59 1.9l-70 70a6.5 6.5 0 009.19 9.19L97.8 74l65.41 65.4a6.5 6.5 0 009.19-9.19l-70-70a6.47 6.47 0 00-4.6-1.87z" data-name="Tracciato 18"/></svg>
        </a>
      </div>
    </div>
    <div class="completion-percentage-bkg">
      <div class="completion-percentage" :style="'width:' + completionPercentage + '%'"></div>
    </div>
    <transition name="fade">
      <MobileSummary v-if="mobileMenuOpened" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { useNavigation, useWidget, useReservation, usePolizze } from '@/composables';
import MobileSummary from './mobileSummary.vue';

const { t } = useI18n();
const { steps, currentStep, backTo } = useNavigation();
const { forcedPickPoint, isLargeScreen, widgetConfig } = useWidget();
const { reservation } = useReservation();
const { totali } = usePolizze();

const goToStep = (step: number) => {
  backTo(step);
}

const navigationContainerClass = computed(() => {
  let count = uiSteps.value ? uiSteps.value.length : 0;
  return `webins-widget-navigation-${count}`;
});

const uiSteps = computed(() => {
  return steps.value.filter(s => {
    if(widgetConfig.value && widgetConfig.value.abilitaAggiuntaCarrello){
      var stepUsati = [1,3,4];
      return stepUsati.indexOf(s.step) >= 0
    }
      return s.step >= 0;
  });
});

const completionPercentage = computed(() => {
  const numSteps = uiSteps.value.length;
  let stepsDone = currentStep.value;
  if(stepsDone > 0 && forcedPickPoint.value != null) {
    stepsDone = stepsDone - 1;
  }
  const percentage = stepsDone * 100 / numSteps;
  return Math.round(percentage);
});

const mobileMenuOpened = ref(false);
watch(currentStep, () => mobileMenuOpened.value = false);


</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>