import { AssociaDistributoriPacchettiExtended } from "./AssociaDistributoriPacchetti";
import { datiAderente } from "./DatiAderente";
import { ParametriPreventivatoreFasceEta } from "./ParametriPreventivatore";

export class RigaCarrello {
	itemID: number;
	userID: string;
	riga: number;
	distributoreID: number;
	pacchettoID: number;
	inizioViaggio?: Date;
	fineViaggio?: Date;
	statoID: number;
	nazionalitaID: number;
	numeroPartecipanti: number;
	tesseraSingola: boolean | null;
	adeguatezza: boolean | null;
	aderente: datiAderente | null;
	codiceRUI: string;
	imageFilePartecipanti: string;
	elencoPartecipanti: string;
	importoViaggio: number;
	secondoImporto: number;
	numeroAlloggi: number | null;
	codicePromo: string;
	note: string;
	riferimentoPratica: string;
	prodottiCollegati: string;
	infoTotali: string;
	istanteInserimento: string | null;
	partecipanti: string;
	ulterioriDati: string;
	fasceEta: ParametriPreventivatoreFasceEta[] | null;
	aderenteIsPartecipante: boolean | null;
	widgetCode: string;
}

export class RigaCarrelloDati {
	datiProdotto: AssociaDistributoriPacchettiExtended;
	datiCarrello: RigaCarrello;

	constructor() {
        this.datiCarrello = new RigaCarrello();
        this.datiProdotto = new AssociaDistributoriPacchettiExtended();
    };
}