<template>
    <div v-if="isloading" class="loading">
        <p>{{ t("common.loadingText") }}</p>
    </div>  
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        isloading: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        const { t } = useI18n();
        return { t }
    }
});
</script>