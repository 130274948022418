<template>
    <div class="webins-widget-step-cols webins-step-pagamento">
        <div>
            <div class="center">
                <!-- <h1>Completa l'acquisto</h1> -->
            </div>

            
            <div class="box">
                <!-- <h3>Le tue polizze</h3> -->
                <ReservationItems :enableDel="false" :showMainMedia="false" :enableDetailButton="true"/>

                <!-- <div class="payment-coupon">
                    <strong>Codice sconto</strong><br />
                    <template v-if="reservation.appliedCoupon == null">
                        <input type="text" v-model="coupon" />&nbsp;
                        <button class="btn btn-small" @click="applyCoupon">Applica</button>
                        <div class="coupon-warning-box" v-if="couponError != null">Coupon non valido: {{ couponError }}</div>
                    </template>
                    <template v-else>
                        Coupon: <strong>{{ reservation.appliedCoupon }}</strong>
                    </template>
                </div> -->
            </div>

            <!-- <div style="width: 75%; margin: 0 auto">
                <p>
                    <strong>Condizioni generali *</strong><br />
                    <label>
                        <input type="checkbox" name="checkbox" v-model="acceptConditions" />
                        <span class="checkboxtext">Ho preso visioni e accetto le <a href="#" @click="popup.show($event)">condizioni di noleggio</a></span>
                    </label>
                </p>
                <p>
                    <strong>Consenso Privacy *</strong><br />
                    <label>
                        <input type="checkbox" name="checkbox" v-model="acceptPrivacy" />
                        <span class="checkboxtext"> Dichiaro di aver letto e compreso <a target="_new" :href="privacyUrl">l’informativa privacy</a>. </span>
                    </label>
                </p>
            </div> -->

            <div v-if="nextStepWarnings && nextStepWarnings.length > 0" class="box-warning">
                <ul>
                    <li v-for="(warn, i) in nextStepWarnings" :key="i">{{ warn }}</li>
                </ul>
            </div>

             <!-- <div  style="margin-top: 30px">
                <button class="btn" @click="onTestConferma">Test Pagina Conferma</button>
                <button class="btn" @click="onTestAcquisto">Test PreAcquisto</button>
                <button class="btn" @click="onTestPostAcquisto">Test Post Pagamento</button>
            </div> -->

            <div class="box box-payment" :class="{ 'payment-disabled': nextDisabled }" v-if="isPaymentInternal">
                <h3>Pagamento</h3>
                <div class="box-failure" v-if="transazione && (transazione.adesioniErrori && transazione.adesioniErrori.length )">
                    <!-- <div>Id prenotazione: {{ reservation.processingData.idReservation }}</div> -->
                    <!-- <div>Id ordine associato: {{ reservation.processingData.idOrder }}</div> -->
                    <!-- <hr /> -->
                    <p  v-for="(err, index) in transazione.adesioniErrori" :key="index" >
                        <strong>{{ err.descrizione }}</strong>
                    </p>
                    <!-- <button v-if="reservation.processingData.idReservation > 0" class="btn" @click="next">Continua</button>-->
                </div>
                
                <PayPalButton />

              
            </div>
            <div v-else class="box box-payment" :class="{ 'payment-disabled': addToCartDisabled }" >
                <button class="btn" @click="addToCart" >Aggiungi al carrello</button>
                <div>
                    <p style="font-size:18px" >{{ msgErrCarrello }}</p>
                </div>
            </div>

            <!-- <div class="center">
                <button class="btn btn-secondary" @click="prev">Torna indietro</button>
            </div> -->
        </div>
        <SummaryRisultati />
        
        <Popup ref="popupAlert" >
            <div style="font-weight: bold" v-html="alertText"></div>
        </Popup>
    </div>
    <Loading :isloading="loading" />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import Loading from "../../loading.vue";
import SummaryRisultati from "../../summary-risultati.vue";
import Popup from "../../popup.vue";
import PayPalButton from "./paypalButton.vue";
import ReservationItems from "./reservationItems.vue";
import { useNavigation, usePolizze, useWidget } from "@/composables";
import { RigaCarrello } from "@/models/RigaCarrello";
import dayjs from "dayjs";
import { CartCallbackItem } from "@/models";

const { prev, next } = useNavigation();
const { options, isPaymentInternal, appCode, destinazioniPreventivatore, callCartCallback } = useWidget();
const { loading, totali, rigaCarrelloDati, acquista, aggiungiACarrello, transazione, finalizzaTransazione, testGetDatiAdesione, msgAddToCart } = usePolizze();

const popupAlert = ref(null);
const alertText = ref(null);
const addToCartDisabled = ref(false);
const msgErrCarrello = ref("");

const privacyUrl = computed(() => {
    if (options.value != null && options.value.privacyUrl != null && options.value.privacyUrl.length > 0) {
        return options.value.privacyUrl;
    }
    return "https://webins.it/privacy-policy";
});


// const addToCartDisabled = computed(() => acceptConditions.value !== true || acceptPrivacy.value !== true || reservation.value.selectedProducts.length <= 0);


const nextDisabled = computed(
    () =>{
        return false; // gestire poi per aggiungere al carrello del sito ospitante
    }
        
);

const nextStepWarnings = computed(() => {
    const warnings: string[] = [];

    return warnings;
});

const addToCart = async () => {
    var riga = rigaCarrelloDati.value.datiCarrello as RigaCarrello;
    debugger
    if(!options.value.addToCartCallback){
        // show msg errore con popup -> callback sito ospitante non impostata
        return;
    }

    var resAcquisto = await aggiungiACarrello(riga, appCode.value);
    debugger
    var msgErr = "";
    if(!resAcquisto){
        msgErr = "Si e' verificato un errore durante l'aggiunta al carrello";
    }
    else if( resAcquisto.adesioniErrori && resAcquisto.adesioniErrori.length){
        msgErr = resAcquisto.adesioniErrori[0].descrizione ||  "Si e' verificato un errore durante l'aggiunta al carrello";
    }
    else if( !resAcquisto.adesioni || !resAcquisto.adesioni.length || !resAcquisto.adesioni[0].guidAdesione ){
        msgErr = "Si e' verificato un errore durante l'aggiunta al carrello";
    }

    if(msgErr){
        alertText.value = msgErr;
        popupAlert.value.show();
        return;
    }

    loading.value = true;
    var guidAdesione = resAcquisto.adesioni[0].guidAdesione;

    var datiAd = resAcquisto.adesioni[0].datiAdesioneECommerce;

    var callbackData : CartCallbackItem = {
        nomePolizza: datiAd.pacchetto,
        terminiECondizioniPolizza: resAcquisto.adesioni[0].terminiECondizioniPolizza,
        guidAdesioneTemporanea: guidAdesione,
        numeroPartecipanti: datiAd.numeroPartecipanti,
        istanteInserimento: datiAd.istanteInserimento,
        inizioViaggio: datiAd.inizioViaggio,
        fineViaggio: datiAd.fineViaggio,
        durataViaggio: resAcquisto.adesioni[0].datiAdesione.durataViaggio,
        destinazione: datiAd.destinazione,
        importoViaggio: datiAd.importoViaggio,
        totalePremioDaPagare: datiAd.totalePremio
    };

    debugger
    
    //var res = callCartCallback(callbackData); // vedere cosa bisogna passare, probabilmente dati polizza
    var res = options.value.addToCartCallback(callbackData); // vedere cosa bisogna passare, probabilmente dati polizza
    res.then((msg)=>{
        debugger
        addToCartDisabled.value = true;
        msgAddToCart.value = msg || ""
        next();
        // OK -> mostra eventuale msg oppure msg default
        // blocca esecuzione widget
        return;
    }).catch((msgErr)=>{
        debugger
        msgErrCarrello.value = msgErr || "Si e' verificato un errore durante l'aggiunta al carrello"
        // show msg errore con popup
        return;
    }).finally(()=>{
        loading.value = false;
    })
};


const onTestConferma = async () => {
    //await testGetDatiAdesione('d349c489-493c-4805-b7ff-bd43958b6f70');
    await testGetDatiAdesione('80a22a8a-f5ce-46bb-a719-cfb1e801efcf');
    //await testGetDatiAdesione('f9cd4345-c246-4dc2-ae4e-6ead11176f3f');
    next();
}

const onTestAcquisto = async () => {
    //var resAcquisto = await acquista(rigaCarrelloDati.value.datiCarrello);

    //console.log(resAcquisto);
}

const onTestPostAcquisto = async() => {
    var res = await finalizzaTransazione();
    console.log(res);
}

</script>
