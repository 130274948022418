<template>
    <div class="" >
        <svg v-if="checked" class="bi-check text-green b-icon bi"  viewBox="0 0 16 16" width="1.5em" height="1.5em" focusable="false" role="img" aria-label="check" xmlns="http://www.w3.org/2000/svg" fill="currentColor" ><g><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></g></svg>
        <svg v-else class="bi-x text-red b-icon bi" viewBox="0 0 16 16" width="1.5em" height="1.5em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" ><g><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></g></svg>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    props: {
        parametri: {
            type: Object,
            default: null
        },
        prodotto: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const { t } = useI18n();

        const checked = computed(() => {
            var result = false;
            
            props.prodotto.parametri.forEach((parametro) => {
                if (parametro.categoria == props.parametri.categoria && parametro.valore && (parametro.tipo !== 1 || parametro.valore !== "0")) {
                    result = true;
                }
            });
            
            return result;
        });

        return { t, checked };
    }
});
</script>
