import { Area } from "@/models";
import { BaseService } from "./BaseService";
import { ApiResponse } from "./responses/ApiResponse";
import { WidgetInitializationResponse } from "./responses/WidgetInitializationResponse";
import { WebinsWidgetInitializationResponse } from "./responses/WebInsWidgetInitializationResponse";
import { TextValueItem } from "@/models/TextValueItem";
import { ParametriPreventivatoreItem } from "@/models/ParametriPreventivatore";
import { AssociaDistributoriPacchettiExtended, gruppoSchedaProdottoParametri } from "@/models/AssociaDistributoriPacchetti";
import { RigaCarrello } from "@/models/RigaCarrello";
import { totaliCarrello } from "@/models/TotaliCarrello";
import { Comune, Provincia } from "@/models/Province";
import { FasciaEta } from "@/models/FasciaEta";
import { RispostaAdesione, RispostaAdesioneMultipla, RispostaAdesioneMultiplaWidget, RispostaAdesioneWidget } from "@/models/RispostaAdesioneMultipla";
import { LoginResponse } from "@/models/LoginResponse";

class WebinsService extends BaseService {
    async getAreas(): Promise<Area[]> {
        const response = await this.tryExecute(this.api.get<Area[]>("lookups/areas"));

        return response.data;
    }

    async login(code: string): Promise<LoginResponse> {
        const response = await this.tryExecute(this.api.post<ApiResponse<LoginResponse>>("login", { widgetCode: code }));
        return this.handleApiResponse(response.data);
    }

    async getDestinazioniPreventivatore(): Promise<TextValueItem[]> {
        const response = await this.tryExecute(this.api.get<TextValueItem[]>("statipreventivatore"));
        return response.data as TextValueItem[];
    }

    async getElencoEta(): Promise<TextValueItem[]> {
        const response = await this.tryExecute(this.api.get<TextValueItem[]>("eta"));

        return response.data as TextValueItem[];
    }

    async getElencoFasceEta(): Promise<FasciaEta[]> {
        const response = await this.tryExecute(this.api.get<FasciaEta[]>("fasceeta"));

        return response.data as FasciaEta[];
    }

    async getElencoProvince(): Promise<Provincia[]> {
        const response = await this.tryExecute(this.api.get<Provincia[]>("province"));

        return response.data as Provincia[];
    }
    
    async getElencoComuni(): Promise<Comune[]> {
        const response = await this.tryExecute(this.api.get<Comune[]>("comuni"));

        return response.data as Comune[];
    }

    async preventivatore(parametri: ParametriPreventivatoreItem): Promise<AssociaDistributoriPacchettiExtended[]> {
        const response = await this.tryExecute(this.api.post<ApiResponse<AssociaDistributoriPacchettiExtended[]>>("preventivatore", parametri));
        return this.handleApiResponse(response.data);
    }

    async getParametriProdotti(): Promise<gruppoSchedaProdottoParametri[]> {
        
        const response = await this.tryExecute(this.api.get<gruppoSchedaProdottoParametri[]>("parametri"));
        //return this.handleApiResponse(response.data);
        
        return response.data as gruppoSchedaProdottoParametri[];
    }

    async getTerminiECondizioni(pacchettoID: number, prodottoID: number): Promise<any[]> {
        
        const response = await this.tryExecute(this.api.get<any[]>(`terminiecondizioni?pacchettoId=${pacchettoID}&prodottoId=${prodottoID}`, { responseType: "blob" }));

        return response as any;
    }

    async getDocumentoPolizza(pacchettoID: number, prodottoID: number, tipoDocumento: number): Promise<any[]> {
        const response = await this.tryExecute(this.api.get<any[]>(`documentoPolizza?tipoDocumento=${tipoDocumento}&pacchettoId=${pacchettoID}&prodottoId=${prodottoID}`, { responseType: "blob" }));

        return response as any;
    }
    

    async getSuggerimentiPolizza(parametri: ParametriPreventivatoreItem, pacchettoID: number): Promise<AssociaDistributoriPacchettiExtended[]> {
        const response = await this.tryExecute(this.api.post<ApiResponse<AssociaDistributoriPacchettiExtended[]>>(`suggerimenti/${pacchettoID}`, parametri));
        return this.handleApiResponse(response.data);
    }

    async getTotaliCarrello(rigaCarrello: RigaCarrello): Promise<totaliCarrello> {
        const response = await this.tryExecute(this.api.post<ApiResponse<totaliCarrello>>(`totali`, rigaCarrello));
        return this.handleApiResponse(response.data);
    }

    async getDescrizioneCommerceBreve(pacchettoID: number): Promise<string> {
        const response = await this.tryExecute(this.api.get<string>(`descrizione/${pacchettoID}`));
        
        return response.data as string;
    }

    async acquista(rigaCarrello: RigaCarrello): Promise<RispostaAdesioneMultipla> {
        const response = await this.tryExecute(this.api.post<ApiResponse<RispostaAdesioneMultipla>>(`acquista`, rigaCarrello));
        debugger
        return this.handleApiResponse(response.data);
    }

    async finalizzaTransazione(transactionID: string): Promise<RispostaAdesione> {
        const response = await this.api.post<ApiResponse<RispostaAdesione>>(`finalizzaTransazione?transactionID=${transactionID}`, null);
        debugger
        return this.handleApiResponse(response.data, true);        
    }

    async notificaPagamentoAnnullato(transactionID: string): Promise<RispostaAdesione> {
        const response = await this.api.post<ApiResponse<RispostaAdesione>>(`annullapagamento?transactionID=${transactionID}`, null);
        debugger
        return this.handleApiResponse(response.data);        
    }
    
    async aggiungiACarrello(rigaCarrello: RigaCarrello): Promise<RispostaAdesioneMultiplaWidget> {
        const response = await this.tryExecute(this.api.post<ApiResponse<RispostaAdesioneMultiplaWidget>>(`aggiungi-carrello`, rigaCarrello));
        debugger
        return this.handleApiResponse(response.data);
    }

    async downloadAdesione(guidAdesione: string): Promise<any> {
        const response = await this.tryExecute(this.api.get<ApiResponse<any>>(`certificatoAdesione/${guidAdesione}`, { responseType: "blob" }));
        
        return response as any;
    }

    
    async caricaAdesione(guidAdesione: string, emailAssociata: string): Promise<RispostaAdesioneWidget> {
        // poi  gestire anche emailAssociata
        const response = await this.tryExecute(this.api.get<ApiResponse<RispostaAdesioneWidget>>(`adesione/${guidAdesione}?email=${emailAssociata}`));
        return response && response.data ? this.handleApiResponse(response.data) : null;
    }

    async testGetDatiAdesione(guidAdesione: string): Promise<RispostaAdesione> {
        const response = await this.tryExecute(this.api.get<ApiResponse<RispostaAdesione>>(`adesione/${guidAdesione}`));
        return this.handleApiResponse(response.data);
    }

    // login
    // refresh token
    // get destinazioni
    // preventivatore ( con calcolo totali )
    // calcolo suggerimenti
    // get dati prodotto
    // calcolo totali
    //
}

export default new WebinsService();
