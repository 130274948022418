<template>
    <div class="widget-tooltip-box">
        <slot />
        <div v-if="isShown" class="widget-tooltip" :class="isShown ? 'widget-tooltip-show' : ''">
            <span class="widget-tooltip-text">{{ text }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, ref, onMounted } from "vue";

const isShown = ref(null);

const props =
    defineProps<{
        text?: string;
    }>();

    

const show = () => {
    if(isShown.value)
        return;
    isShown.value = true;
    document.addEventListener('pointerdown',clickHandler);
};

const clickHandler = () => {
    if(isShown.value)
        close();
}


const close = () => {
    if(!isShown.value)
        return;
    isShown.value = false;
    
    document.removeEventListener('pointerdown',clickHandler);
};

defineExpose({
    show,
    close
})
</script>

<style scoped>
.widget-tooltip-box {
    position: relative;
    display: inline-block;
}


.widget-tooltip-box .widget-tooltip-show.widget-tooltip {
    opacity: 1;
}

.widget-tooltip {
    color: black;
    text-align: center;
    padding: 10px;
    border-radius: 10px;

    width: 300px;
    bottom: 100%;
    left: 50%;
    margin-left: -150px;

    opacity: 0;
    transition: opacity 1s;

    position: absolute;
    z-index: 1000;


    border-color: #303030;
    border-style: solid;
    border-width: 1px;
    background-color: #ffffff;
}

.widget-tooltip-text {
    font-size: 16px;
}

.widget-tooltip-text::after {
    content: " ";
    position: absolute;
    top: 101%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #303030 transparent transparent transparent;
}
</style>
