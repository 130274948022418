import axios from "axios";
import qs from "qs";
import { ApiResponse } from "./responses/ApiResponse";
import { useWidget } from "@/composables";
//let { token } = useWidget();

const _api = axios.create({
  //baseURL: 'https://dp365.rossi-ecocar.com/api/booking/', // process.env.VUE_APP_API_BASE_URL, //"/frontend/booking/",
  baseURL: process.env.VUE_APP_WEBINS_API_BASE_URL, //"/frontend/booking/",
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

export class BaseService {
  init: boolean;
  baseUrl: "";

  get api() {

    if(!this.init){
      this.init = true;

      this.baseUrl = process.env.VUE_APP_WEBINS_API_BASE_URL;
      const { token } = useWidget();

    _api.interceptors.request.use(config => {
			config.headers.common["Authorization"] = "bearer " +  ( token.value|| "" ) ;
			return config;
		});
    }

    return _api;
  }

  handleApiResponse<T>(response: ApiResponse<T>, showAlert?: boolean): T {
    if (!response.success && response.status !== "ok") {
      //Toastr.warning("Errore nella richiesta al server.", response.messages);
      // TODO
      console.warn(
        "Server response valid but request unsuccesful.." +
          JSON.stringify(response.messages)
      );

      if( showAlert && response.messages && response.messages.length){
        alert(JSON.stringify(response.messages));
      }
    }

    return response.data;
  }

  tryExecute = async <T>(func: Promise<T>): Promise<T | null> => {
    try {
      const result = await func;
      return result;
    } catch (err) {
      console.error("Server communication error.. " + err);
      return null;
    }
  };
}
