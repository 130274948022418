<template>
    <div class="preventivatore-result-container">
        <div class="webins-row mt-3">
            <div class="d-flex text-muted">
                <!-- <b-icon scale="1" icon="arrow-left" aria-hidden="true" class="mr-2"></b-icon> -->
                <!-- <h6 style="text-decoration: underline">Indietro</h6> -->
            </div>
        </div>

        <!-- <div class="webins-row d-flex flex-row justify-content-between py-3">
            <div class="webins-col-6">
                <b>{{ risultatiPreventivatore.length }}</b> prodotti
            </div>
        </div> -->
        <div class="webins-row d-flex" style="justify-content: space-around">
            <div v-for="prodotto in risultatiPreventivatoreFiltrati" :key="prodotto.itemID" class="d-flex  webins-card-polizza" style="margin: 5px">
                <ProdottoCarrelloCard :prodotto="prodotto" :parametri="parametriConValori"> </ProdottoCarrelloCard>
            </div>
        </div>

        <div class="result-list-empty" v-if="risultatiPreventivatoreFiltrati == null || risultatiPreventivatoreFiltrati.length == 0">Nessuna disponibilità per i parametri impostati.</div>
        <!-- <ResultList /> -->
    </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, defineProps, onMounted, ref } from "vue";
import { usePolizze, useNavigation, useWidget } from "@/composables";
import Collapsible from "@/components/collapsible.vue";
import IconCircle from "@/components/icon-circle.vue";
import CheckCategoriaParametri from "@/components/checkCategoriaParametri.vue";
import CheckParametri from "@/components/checkParametri.vue";
import ProdottoCarrello from "@/components/prodotto-carrello.vue";
import ProdottoCarrelloCard from "@/components/prodotto-carrello-card.vue";
import Popup from "@/components/popup.vue";
import { gruppoSchedaProdottoParametri } from "@/models/AssociaDistributoriPacchetti";
import WebinsService from "@/services/WebinsService";
//import ResultList from "./result-list.vue";

const { loading, risultatiPreventivatore, parametriSchedaPreventivatore } = usePolizze();
const { isAppMobile, options } = useWidget();

const categorieEspanse = ref<string[]>([]);

onMounted(async () => {
    /*var self = this;
    eventHub.$on(
        eventHub.events.PRODUCT_SEARCHED,
        () => self.changeProdottiScroll()
    );

    eventHub.$on(
        eventHub.events.TOGGLE_PREFERITI,
        async () => {
            self.togglePreferiti();
        }
    );*/
});

const parametriConValori = computed(() => {
    // gruppoSchedaProdottoParametri
    if (parametriSchedaPreventivatore.value == null) return [];
    return parametriSchedaPreventivatore.value.filter((x) => parametroHasValori(x));
});

const risultatiPreventivatoreFiltrati = computed(() => {
    if(!options.value.prodotti || !options.value.prodotti.length)
        return risultatiPreventivatore.value;
    var filtrati = risultatiPreventivatore.value.filter((obj) => {
        return options.value.prodotti.includes(obj.pacchettoID);
    })
    return filtrati;
});

const parametroHasValori = (parametro: gruppoSchedaProdottoParametri) => {
    if (risultatiPreventivatore == null || risultatiPreventivatore.value == null || risultatiPreventivatore.value.length == 0) return false;
    // basta che ci sia almeno un prodotto con almeno un parametro valorizzato per categoria del parametro in questione [quello passato al metodo]
    return risultatiPreventivatore.value.find((p) => p.parametri.find((pp) => pp.categoria == parametro.categoria && pp.valore && pp.valore != "0")) != null;
};

const expandCategoria = (categoria) => {
    if (categorieEspanse.value.indexOf(categoria) == -1) categorieEspanse.value.push(categoria);
    else categorieEspanse.value = categorieEspanse.value.filter((x) => x != categoria);
    // Vue.set(this.categorie, categoria, !this.categorie[categoria]);
};

const isCategoriaEspanded = (categoria) => {
    return categorieEspanse.value.find((x) => x == categoria) != null;
};
</script>
