
export class datiAderente {
	nome: string;
	cognome: string;
	indirizzo: string;
	cap: string;
	residenza: string;
	citta: string;
	comune: string;
	codiceProvincia: string;
	codiceFiscale: string;
	dataNascita: Date;
	telefono: string;
	email: string;
	confermaEmail: string;
	consensoMarketing: boolean;
}