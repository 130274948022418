<template>
    <span class="paypal-btn-container" ref="paypalBtn"></span>
    {{ initState }}
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { loadScript } from "@paypal/paypal-js";
import { useNavigation, usePolizze, useWidget } from "@/composables";
import { RigaCarrello } from "@/models/RigaCarrello";

export default defineComponent({
    // props: {
    //     isloading: {
    //         type: Boolean,
    //         default: false
    //     },
    // }
    setup() {
        const paypalBtn = ref(null);
        const { loading, acquista, transazione, finalizzaTransazione, notificaPagamentoAnnullato, rigaCarrelloDati } = usePolizze();
        const { defaultPaymnetAppId, appCode } = useWidget();
        const { next } = useNavigation();
        const initState = ref("");

        onMounted(async () => {
            try {
                //var resAcquisto = await acquista(rigaCarrelloDati.value.datiCarrello);

                initState.value = "inizializzazione servizi pagamento..";

                debugger
                // use pick point appId for payment process if configured, otherwise use default global appId
                let clientId = defaultPaymnetAppId.value; // process.env.VUE_APP_PAPYPAL_CLIENT_ID
               
                console.log("PP client id: " + clientId);
                // Paypal Script Loader
                // https://github.com/paypal/paypal-js
                const paypal = await loadScript({
                    "client-id": clientId,
                    "currency": "EUR",
                    "disable-funding": "bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo"
                });
                paypal.Buttons(paypalActions).render(paypalBtn.value);

                initState.value = "";
            }
            catch(err) {
                console.error("failed to load the PayPal JS SDK script", err);
                initState.value = "Si sono verificati problemi in fase di inizializzazione dei servizi di pagamento.";
            };
        });

        const paymentInProcess = ref(false);

        const paypalActions = {
            createOrder: async (data: any, actions: any) => {
                /*const created = await createReservation();
                if(created == true) {
                    return reservation.value.processingData.idPaymentOrder;
                }*/
                var riga = rigaCarrelloDati.value.datiCarrello as RigaCarrello;

                var resAcquisto = await acquista(riga as any, appCode.value);
                debugger
                if( resAcquisto && resAcquisto.transactionID)
                    return resAcquisto.transactionID;

                if(resAcquisto && resAcquisto.adesioniErrori){
                    /*if(!transazione.value.adesioniErrori )
                        transazione.value.adesioniErrori = [];*/
                }

                // Fallita creazione prenotazione e ordine di pagamento
                return null;
            },
            onApprove: async (data: any, actions: any) => {
                paymentInProcess.value = true;
                try {
                    // chiamata server per generazione autorizzazione server to server
                    // const authSuccess = await authorizeReservation();
                    // if(authSuccess == true) {
                    //     console.log("Acquisto autorizzato!");
                    //     next();
                    // }

                    const authSuccess = await finalizzaTransazione();
                    if(authSuccess && authSuccess.guidAdesione ) {
                        console.log("Acquisto autorizzato!");
                        next();
                    }

                    
                }
                catch (err) {
                    // TODO: comunicare e gestire fallimento autorizzazione
                    // salvare idordine e tentare pagamento?
                    // rollback sessione/ordine creato?
                    console.warn("C'è stato un problema con il pagamento della prenotazione..");
                    throw(err);
                }
                finally {
                    paymentInProcess.value = false;
                }
            },
            onError: (err: any) => {
                console.error('error from the onError callback in PayPal process', err);
                // if(reservation.value.processingData.failureReason == null || reservation.value.processingData.failureReason.length <= 0) {
                //     reservation.value.processingData.failureReason = "Errors in payment process..";
                // }
                // reservation.value.processingData.failure = true;
            },
            onCancel: async () => {
                console.error('Payment operation canceled by user..');
                
                await notificaPagamentoAnnullato();
                debugger
                if(!transazione.value.adesioniErrori )
                    transazione.value.adesioniErrori = [];
                transazione.value.adesioniErrori.push({codice: "", descrizione: "Acquisto annullato", data: null});
            }
        };

        return { paypalBtn, initState }
    }
});
</script>