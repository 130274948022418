import { App, createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import AppWebins from "./AppWebins.vue";
import { useWidget } from "./composables/useWidget";

//import Datepicker from 'vue3-datepicker'
// import VueDatePicker from '@mathieustan/vue-datepicker';
// import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

import { languages, defaultLocale } from "./localization";
import { WidgetOptions } from "./models";

const getContainer = (el: string) => {
  const widgetContainer = document.querySelector(el);

  if (widgetContainer && widgetContainer.innerHTML.trim().length == 0) {
    widgetContainer.innerHTML = `<div style='color: lightgrey; padding: 20px; background-color: white; border: 1px solid gainsboro; text-align: center;'>Loading widget..</div>`;
  }

  return widgetContainer;
};


let webinsApp: App = null;

const webinsBooking = (options: WidgetOptions) => {
  //(el: string, code: string = null) => {

  const widgetContainer = getContainer(options.el);

  const { setupAppWebins, mapServiceApiKey } = useWidget();

  const messages = Object.assign(languages);
  const i18n = createI18n({
    legacy: false,
    locale: options.lang || defaultLocale,
    fallbackLocale: "en",
    messages,
  });

  if (webinsApp) webinsApp.unmount();

  if (!options.scope) options.scope = 1;
  
  setupAppWebins(options)
    .then(() => {
      webinsApp = createApp(AppWebins);
      //webinsApp.component('date-picker', Datepicker)
      webinsApp
        .use(i18n)
        .use(VueGoogleMaps, {
          load: {
            key: mapServiceApiKey.value,
          },
        })
        .mount(options.el);
    })
    .catch((err) => {
      console.error("Error initializing booking widget: " + err);
      if (widgetContainer)
        widgetContainer.innerHTML = `<div style='color: indianred; padding: 20px; background-color: white; border: 1px solid gainsboro; text-align: center;'>Widget initialization error!</div>`;
    });
};

(window as any).initWebinsWidget = webinsBooking;
