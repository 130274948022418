import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCheck = _resolveComponent("IconCheck")!
  const _component_IconX = _resolveComponent("IconX")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.rightFieldType == 1)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.checkedTipo1)
            ? (_openBlock(), _createBlock(_component_IconCheck, { key: 0 }))
            : (_openBlock(), _createBlock(_component_IconX, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == 2)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.prodottoParametro == null || _ctx.prodottoParametro.valore == null || _ctx.prodottoParametro.valore == '')
            ? (_openBlock(), _createBlock(_component_IconX, { key: 0 }))
            : ((_ctx.prodottoParametro.valore == _ctx.parametro.valore) == '-1')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Illimitato"))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.numberToPercentage(_ctx.prodottoParametro.valore, _ctx.prodottoParametro.decimalSlot || 0)), 1))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == 3)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.prodottoParametro == null || _ctx.prodottoParametro.valore == null || _ctx.prodottoParametro.valore == '')
            ? (_openBlock(), _createBlock(_component_IconX, { key: 0 }))
            : ((_ctx.prodottoParametro.valore == _ctx.parametro.valore) == '-1')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Illimitato"))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.numberToCurrency(_ctx.prodottoParametro.valore, 0)), 1))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == 4)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          (_ctx.checkedTipo4)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.numberToDecimal(_ctx.prodottoParametro.valore, _ctx.prodottoParametro.decimalSlot || 0)), 1))
            : (_openBlock(), _createBlock(_component_IconX, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == 5)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
          (_ctx.checkedTipo5)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.numberToInteger(_ctx.prodottoParametro.valore)), 1))
            : (_openBlock(), _createBlock(_component_IconX, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == 6)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
          (_ctx.checkedTipo6)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.checkedTipo6), 1))
            : (_openBlock(), _createBlock(_component_IconX, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == 7)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
          (_ctx.checkedTipo7)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.checkedTipo7), 1))
            : (_openBlock(), _createBlock(_component_IconX, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.rightFieldType == -1)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
          (_ctx.checkedTipoText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.checkedTipoText
              }, null, 8, _hoisted_10))
            : (_openBlock(), _createBlock(_component_IconX, { key: 1 }))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}